<template>
  <div>
    <div class="toggle-container" v-if="isMobile">
      <button @click="toggleView" class="toggle-view-button">
        {{ showHotelsList ? 'Voir la carte' : 'Voir la liste' }}
      </button>
      <div class="filter-checkbox">
        <input type="checkbox" v-model="filterBudget" id="filterBudgetMobile">
        <label for="filterBudgetMobile">Masquer les hôtels hors budget</label>
      </div>
    </div>

    <div class="hotel-columns-container">
      <div v-if="showHotelsList" class="hotels-list">
        <div class="filter-block">
          <div class="filter-dropdown" style="width: 32%;">
            <select class="filter-button" v-model="sortBy">
              <option value="" disabled selected>Trier</option>
              <option value="price-asc">Prix croissant</option>
              <option value="price-desc">Prix décroissant</option>
              <option value="rating">Note</option>
            </select>
          </div>
          <div class="filter-dropdown" style="width: 25%;">
            <select class="filter-button" v-model="selectedStar">
              <option value="" disabled selected>★</option>
              <option value="" selected>Tous</option> 
              <option value="1">1+ ★</option>
              <option value="2">2+★</option>
              <option value="3">3+ ★</option>
              <option value="4">4+ ★</option>
              <option value="5">5 ★</option>
            </select>
          </div>
          <div class="filter-dropdown" style="width: 40%;">
            <select class="filter-button" v-model="selectedAccommodationType">
              <option value="" disabled selected>Type</option>
              <option value="">Tous les logements</option> <!-- Option "Tous" -->
              <option v-for="type in accommodationTypeCounts" :key="type.kind" :value="type.kind">
                {{ type.name }} ({{ type.count }})
              </option>
            </select>
          </div>

        </div>

        <div class="hotel-cards" v-if="budget !== null" ref="hotelCards">

          <div
            v-for="(hotel, index) in filteredAndSortedHotels"
            :key="index"
            :class="getHotelCardClass(hotel)"
            @click="centerMapOnHotel(hotel)"
          >
            <div class="hotel-image-container">
              <img :src="processHotelImage(hotel)" :alt="hotel.name" class="hotel-image">
              <div v-if="hotel.isOverBudget" class="watermark"><p>Hors budget</p></div>
            </div>
            <div class="hotel-info">
              <h3 class="hotel-name">{{ hotel.name }}</h3>
              <div class="rating-score">
                <div class="hotel-rating">
                  <span class="stars">{{ generateStarRating(hotel.starRating) }}</span>
                </div>
                <div class="hotel-score" v-if="hotel.ratings.rating && hotel.ratings.rating > 0">
                  {{ hotel.ratings.rating }}/10
                </div>
              </div>
              <div class="price-details">
                <div class="hotel-price">{{ hotel.dailyPrice }}€ <span>/ nuit</span></div>
                <button class="details-button" @click.stop="displayHotelDetails(hotel.id)">+ de détails</button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Carte Leaflet pour ordinateur -->
      <div v-if="!isMobile" class="hotels-map" ref="mapDesktop">
        <div class="filter-checkbox-desktop">
          <input type="checkbox" v-model="filterBudget" id="filterBudgetDesktop">
          <label for="filterBudgetDesktop">Masquer les hôtels hors budget</label>
        </div>
      </div>
      <!-- Carte Leaflet pour mobile -->
      <div v-if="isMobile && !showHotelsList" class="hotels-map-mobile" ref="mapMobile"></div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase.js";
import L from 'leaflet';
import { markRaw } from 'vue'; // Importer markRaw

const accommodationTypesMap = {
  Resort: 'Resort',
  Sanatorium: 'Sanatorium',
  Guesthouse: 'Maison d’hôtes',
  Mini_hotel: 'Mini-hôtel',
  Castle: 'Château',
  Hotel: 'Hôtel',
  Boutique_and_Design: 'Boutique et Design',
  Apartment: 'Appartement',
  Cottages_and_Houses: 'Gîtes et maisons',
  Farm: 'Ferme',
  Villas_and_Bungalows: 'Villas et Bungalows',
  Camping: 'Camping',
  Hostel: 'Auberge de jeunesse',
  BNB: 'Chambre d’hôtes',
  Glamping: 'Glamping',
  Apart_hotel: 'Appart’hôtel'
};

// Fonction utilitaire pour remplacer la taille dans l'URL de l'image
function replaceSizeInImageUrl(imageUrl) {
  return imageUrl.replace("{size}", "x500");
}

export default {
  name: "HotelMain",
  props: ["docRef", "exactDates", "fromProfile"],
  data() {
    return {
      sortBy: "",
      selectedStar: "",
      selectedAccommodationType: "",
      allHotels: [],
      mapDesktop: null,
      mapMobile: null,
      hotelMarkers: [], // Nouvelle propriété pour stocker les marqueurs des hôtels
      selectedHotel: null,
      isMobile: false,
      showHotelsList: true,
      tripData: {},
      budget: null,
      nights: 0,
      totalHotelPrice: 0,
      filterBudget: false,
      accommodationTypeCounts: [],
    };
  },
  async mounted() {
    this.checkIfMobile();
    const localDocRef = this.docRef;
    if (localDocRef) {
      await this.loadHotels(localDocRef);
    } else {
      console.error("docRef is missing");
    }
    this.loadTripDatas(this.docRef);
  },

  computed: {
    filteredAndSortedHotels() {
      let hotels = this.allHotels;

      // Filtrer les hôtels qui ont un nom valide
      hotels = hotels.filter(hotel => hotel.name && hotel.name.trim() !== "");

      // Enlever les doublons par nom
      const uniqueHotels = [];
      const hotelNames = new Set();
      hotels.forEach(hotel => {
        if (!hotelNames.has(hotel.name)) {
          hotelNames.add(hotel.name);
          uniqueHotels.push(hotel);
        }
      });

      // Appliquer le filtre par étoile seulement si une valeur est sélectionnée
      if (this.selectedStar) {
        hotels = uniqueHotels.filter(hotel => hotel.starRating >= parseInt(this.selectedStar));
      } else {
        hotels = uniqueHotels; // Si "Tous" est sélectionné, ne filtrez pas par étoiles
      }

      // Appliquer le filtre par type d'hébergement
      if (this.selectedAccommodationType) {
        hotels = hotels.filter(hotel => hotel.kind === this.selectedAccommodationType);
      }

      // Appliquer le tri
      if (this.sortBy === "price-asc") {
        hotels = hotels.sort((a, b) => a.dailyPrice - b.dailyPrice);
      } else if (this.sortBy === "price-desc") {
        hotels = hotels.sort((a, b) => b.dailyPrice - a.dailyPrice);
      } else if (this.sortBy === "rating") {
        hotels = hotels.sort((a, b) => {
          const ratingA = a.ratings.rating || 0;
          const ratingB = b.ratings.rating || 0;
          return ratingB - ratingA;
        });
      }

      // Calculer si l'hôtel dépasse le budget
      hotels.map(hotel => {
        const totalHotelPrice = this.calculateTotalPrice(hotel);
        hotel.isOverBudget = totalHotelPrice > this.budget;
        return hotel;
      });

      // Appliquer le filtre de budget
      if (this.filterBudget) {
        hotels = hotels.filter(hotel => !hotel.isOverBudget);
      }

      return hotels;
    },
    
    accommodationTypesWithCount() {
      const filteredHotels = this.filteredAndSortedHotels;

      const counts = filteredHotels.reduce((acc, hotel) => {
        const kind = hotel.kind;
        if (kind && accommodationTypesMap[kind]) {  // Vérifiez que le type d'accommodation existe dans le map
          if (acc[kind]) {
            acc[kind]++;
          } else {
            acc[kind] = 1;
          }
        }
        return acc;
      }, {});

      return Object.keys(counts).map(kind => ({
        kind,
        name: accommodationTypesMap[kind] || kind, // Utilisez le type brut si la traduction est absente
        count: counts[kind]
      })).sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
  },

  watch: {
    filterBudget() {
      this.updateMap(); // Update the map when filterBudget changes
    },
    sortBy() {
      this.scrollToTop();
      this.updateMap(); // Update the map when sorting changes
    },
    selectedStar() {
      this.scrollToTop();
      this.updateMap(); // Update the map when star rating filter changes
    },
    selectedAccommodationType() {
      this.scrollToTop();
      this.updateMap(); // Update the map when accommodation type filter changes
    },
  },
  methods: {
    async loadTripDatas(docRef) {
      try {
        const tripDataRef = doc(db, "tripdatas", docRef);
        const tripDataSnapshot = await getDoc(tripDataRef);
        if (tripDataSnapshot.exists()) {
          const data = tripDataSnapshot.data();
          this.tripData = data;
          this.budget = this.tripData.budget;
          if(this.tripData.selectedFlight) {
            this.budget = this.budget - this.tripData.selectedFlight.price;
          } else if (this.tripData.selectedGroundTransportation) {
            this.budget = this.budget - this.tripData.selectedGroundTransportation.outboundTrip.price - this.tripData.selectedGroundTransportation.returnTrip.price;
          }
          console.log("Budget :", this.budget);
        } else {
          console.log("Problème de chargement des données");
        }
        await this.calculateNights();
      } catch (error) {
        console.error("Problème lors du chargement des données : ", error);
      }
    },
    scrollToTop() {
      const hotelCards = this.$refs.hotelCards;
      if (hotelCards) {
        hotelCards.scrollTop = 0;
      }
    },
    getHotelCardClass(hotel) {
      return {
        'hotel-card-red': hotel.isOverBudget,
        'hotel-card': !hotel.isOverBudget,
        'selected-hotel': hotel === this.selectedHotel
      };
    },
    async calculateNights() {
      if (this.tripData.selectedFlight) {
        this.checkin = this.tripData.selectedFlight.departureDateLandingDisplay;
        this.checkout = this.tripData.selectedFlight.returnDateTakeOffDisplay;
      } else if (this.tripData.selectedGroundTransportation) {
        this.checkin = this.tripData.selectedGroundTransportation.outboundTrip.arrivalDateDisplay;
        this.checkout = this.tripData.selectedGroundTransportation.returnTrip.departureDateDisplay;
      } else {
        console.log("Departure Date:", this.tripData.departureDate);
        this.checkin = `Entre ${this.formatDates(this.tripData.departureDate)}`;
        this.checkout = `Et ${this.formatDates(this.tripData.returnDate)}`;
      }

      this.nights = this.tripData.nightsInDest;
      //console.log("Total nights :", this.nights);
    },
    parseDate(date) {
      if (typeof date === 'string' || date instanceof String) {
        return new Date(date);
      } else if (date instanceof Date) {
        return date;
      } else if (date && typeof date.toDate === 'function') {
        return date.toDate();
      } else {
        return new Date(date); // Fallback to creating a new Date object
      }
    },
    formatDates(date) {
      const options = { weekday: 'short', day: 'numeric', month: 'short' };
      const parsedDate = this.parseDate(date);
      return parsedDate.toLocaleDateString('fr-FR', options);
    },
    calculateTotalPrice(hotel){

      const nbRooms = Math.ceil(this.tripData.nbPerson / 2);

      const totalPrice = this.nights * nbRooms * (hotel.dailyPrice || 0) / this.tripData.nbPerson;
      //console.log("totalPrice :", totalPrice);
      return totalPrice;
    },
    async loadHotels(docRef) {
      try {
        const tripDataRef = doc(db, "tripdatas", docRef);
        const tripDataSnapshot = await getDoc(tripDataRef);

        if (tripDataSnapshot.exists()) {
          const tripData = tripDataSnapshot.data();
          this.city = tripData.city;

          const hotelsRef = collection(db, "hotelInformations");
          const q = query(hotelsRef, where("linkedDocumentId", "==", docRef));
          const querySnapshot = await getDocs(q);

          this.allHotels = []; // Clear existing hotels
          querySnapshot.forEach(doc => {
            // Include document ID in hotel object
            const hotelWithId = {
              id: doc.id,
              ...doc.data()
            };
            this.allHotels.push(hotelWithId);
          });

          this.calculateAccommodationTypes();

          // Initialize Leaflet map after hotels are loaded
          this.$nextTick(() => {
            this.initializeMap();
          });
        } else {
          console.log("No trip data found for the given docRef.");
        }
      } catch (error) {
        console.error("Error getting hotel documents:", error);
      }
    },
    centerMapOnHotel(hotel) {
      const map = this.isMobile ? this.mapMobile : this.mapDesktop;
      if (map) {
        map.setView([hotel.location.latitude, hotel.location.longitude], 15);
        this.$nextTick(() => {
          // Ouvrir le popup du marqueur correspondant après que la vue soit centrée
          const marker = this.hotelMarkers.find(m => m.hotelId === hotel.id);
          if (marker) {
            marker.marker.openPopup();
          }
        });
      }
    },

    moveHotelToFront(clickedHotel) {
      const index = this.allHotels.findIndex(hotel => hotel === clickedHotel);
      if (index !== -1) {
        const hotelToMove = this.allHotels.splice(index, 1)[0];
        this.allHotels.unshift(hotelToMove);
        this.selectedHotel = hotelToMove;
      }
    },

    displayHotelDetails(hotelId) {
      this.$emit('displayHotelDetails', hotelId);
      this.$emit('updateStep', 'selectHotelDetails');
    },

    processHotelImage(hotel) {
      if (hotel.images && hotel.images.length > 0) {
        return replaceSizeInImageUrl(hotel.images[0]);
      } else {
        return require('@/assets/img/voyage/hotel.jpg');
      }
    },

    generateStarRating(score) {
      const roundedScore = Math.round(score);
      return "★".repeat(roundedScore);
    },

    toggleView() {
      this.showHotelsList = !this.showHotelsList;
      if (!this.showHotelsList) {
        this.$nextTick(() => {
          this.initializeMap();
        });
      }
    },

    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    createMarkers(map) {
      const customIcon = L.icon({
        iconUrl: require('@/assets/img/voyage/hotels/pin-point.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [-20, -18] // Adjust popup position
      });

      const customDivIcon = (hotel) => {
        return L.divIcon({
          className: 'custom-div-icon',
          html: `<div class="daily-price-bubble">${hotel.dailyPrice}€</div>`,
          iconSize: [60, 40],
          iconAnchor: [50, 20],
          popupAnchor: [-15, -15] // Adjust popup position
        });
      };

      this.filteredAndSortedHotels.forEach(hotel => {
        if (hotel.location && hotel.location.latitude && hotel.location.longitude) {
          const marker = markRaw(L.marker([hotel.location.latitude, hotel.location.longitude], {
            icon: customIcon
          }).addTo(map));

          this.hotelMarkers.push({ hotelId: hotel.id, marker }); // Store marker with hotel ID

          marker.on('click', () => {
            this.moveHotelToFront(hotel);
            map.setView([hotel.location.latitude, hotel.location.longitude], 15);
          });

          map.on('zoomend', () => {
            if (map.getZoom() >= 12) {
              marker.setIcon(customDivIcon(hotel));
            } else {
              marker.setIcon(customIcon);
            }
          });

          const popupContent = document.createElement('div');
          popupContent.innerHTML = `
            <b>${hotel.name}</b><br>
            ${hotel.address}<br>
          `;
          const detailsButton = document.createElement('button');
          detailsButton.textContent = '+ de détails';
          detailsButton.classList.add('details-button-popup');
          detailsButton.addEventListener('click', () => {
            this.displayHotelDetails(hotel.id);
          });
          popupContent.appendChild(detailsButton);

          marker.bindPopup(popupContent);
        }
      });
    },
    initializeMap() {
      const mapContainer = this.isMobile && !this.showHotelsList ? this.$refs.mapMobile : this.$refs.mapDesktop;
      if (this.allHotels.length > 0 && mapContainer) {
        const firstHotel = this.allHotels[0];
        const initialPosition = [firstHotel.location.latitude, firstHotel.location.longitude];

        const map = markRaw(L.map(mapContainer).setView(initialPosition, 13)); // Utiliser markRaw
        if (this.isMobile) {
          this.mapMobile = map;
        } else {
          this.mapDesktop = map;
        }

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap'
        }).addTo(map);

        this.createMarkers(map); // Call createMarkers to add markers to the map
      }
    },
    updateMap() {
      const map = this.isMobile ? this.mapMobile : this.mapDesktop;
      if (map) {
        // Clear existing markers
        this.hotelMarkers.forEach(marker => map.removeLayer(marker.marker));
        this.hotelMarkers = [];

        this.createMarkers(map); // Call createMarkers to add updated markers to the map
      }
    },
    calculateAccommodationTypes() {
      const uniqueHotels = [];
      const hotelNames = new Set();

      // Filtrer les hôtels pour ne garder que ceux avec des noms uniques
      this.allHotels.forEach(hotel => {
        if (hotel.name && !hotelNames.has(hotel.name)) {
          hotelNames.add(hotel.name);
          uniqueHotels.push(hotel);
        }
      });

      // Compter les types d'accommodation parmi les hôtels uniques
      const counts = uniqueHotels.reduce((acc, hotel) => {
        const kind = hotel.kind;
        if (kind && accommodationTypesMap[kind]) {
          if (acc[kind]) {
            acc[kind]++;
          } else {
            acc[kind] = 1;
          }
        }
        return acc;
      }, {});

      // Stocker les résultats dans une propriété de données
      this.accommodationTypeCounts = Object.keys(counts).map(kind => ({
        kind,
        name: accommodationTypesMap[kind] || kind,
        count: counts[kind]
      })).sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    },

  },
};
</script>



<style scoped>
.toggle-container {
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.toggle-view-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 250px;
  font-weight: 600;
  margin-top: 0;
}

.toggle-view-button:hover {
  background-color: var(--jaune);
}

/* Hotel avec map */

.hotel-columns-container {
  display: flex;
  width: 100%;
  height: 82vh;
  justify-content: space-between;
}

.hotels-list {
  flex:auto;
  margin-right: 1%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 10px 20px;
}

.hotels-map {
  flex: 0 0 54%;
  background-color: white;
  margin-right: 0;
  padding: 10px;
  z-index: 0;
}

.hotels-map-mobile {
  width: 100%;
  height: 75vh;
  background-color: white;
  margin: 0px auto;
}

.filter-block {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 80px;
  width: 98%;
  box-sizing: border-box;
  margin-top: -15px;
  justify-content: space-between;
  gap: 10px;
  padding: 0 5px;
}

.filter-dropdown {
  position: relative;
}

.filter-button {
  position: relative;
  background-color: white;
  color: var(--vert);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 30px 10px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('@/assets/img/voyage/hotels/down-arrow-green.png');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-button::after {
  content: '';
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-image: url('@/assets/img/voyage/hotels/down-arrow-green.png');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

.filter-button select {
  appearance: none;
  background: none;
  border: none;
  color: var(--vert);
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 15px;
  cursor: pointer;
  display: inline-block;
}

.filter-button option {
  background-color: white;
  color: var(--vert);
  padding: 10px;
  border: none;
  cursor: pointer;

}

.filter-button option:hover {
  background-color: var(--jaune);
  color: white;
}

.hotel-cards {
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: calc(82vh - 80px);
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: var(--vert) #e0e0e0;
  gap: 20px;
}

.hotel-cards::-webkit-scrollbar {
  width: 10px;
}

.hotel-cards::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.hotel-cards::-webkit-scrollbar-thumb {
  background-color: var(--vert);
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}

.hotel-card {
  display: flex;
  width: 98%;
  height: 150px;
  min-height: 150px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.hotel-card-red {
  display: flex;
  width: 98%;
  height: 150px;
  min-height: 150px;
  background-color: #f0caca; /* Light red background */
  border: 1px solid rgb(150, 30, 30); /* Red border */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}


.hotel-image-container {
  flex: 0 0 40%;
  position: relative;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(150, 30, 30, 0.4);
  color: rgb(255, 255, 255);
  height:100%;
  width:100%;
  border-radius: 5px;
  font-size: 1rem;
  pointer-events: none; /* Ensure the watermark doesn't interfere with image clicks */
  display: flex;
  align-items: center;
  justify-content: center;
}

.watermark p {
  transform: rotate(-30deg);
}

.hotel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.hotel-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0px 10px 20px;
  box-sizing: border-box;
}

.hotel-name {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
  color: var(--vert);
}

.hotel-rating {
  display: flex;
  align-items: center;
}

.stars {
  color: var(--jaune);
  margin-right: 5px;
  margin-top: 8px;
}

.hotel-score {
  background-color: var(--jaune);
  color: white;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.8rem;
  width: fit-content;
  margin-top: 5px;
}

.hotel-price {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--vert);
  margin-top: 12px;
}

.hotel-price span {
  font-weight: 400;
  font-size: 0.9rem;
}

.details-button {
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 7px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 15px;
  align-self: flex-start;
}

.details-button-popup {
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 2px 4px !important;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 0px;
}

.price-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filter-button option {
  font-size: 'Roboto';
}

.rating-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .hotel-columns-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: 30px auto;
  }

  .hotels-list {
    width: 95%;
  }

  .hotels-map {
    display: none;
  }

  .filter-block {
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    gap: 5px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .filter-dropdown {
    width: 30%;
  }

  .toggle-container {
    display: flex;
    justify-content: center;
    margin: 10px auto -15px auto;
  }

  .toggle-view-button {
    font-size: 1rem;
    padding: 10px 15px;
    width: 200px;
  }

  .details-button {
    background-color: var(--vert);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 4px 6px;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 400;
    margin-right: 15px;
    margin-top: 10px;
  }

}

.filter-checkbox,
.filter-checkbox-desktop {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.filter-checkbox-desktop {
  padding: 10px;
  background-color: var(--vert);
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2000;
  gap: 20px;
  color: var(--white);
  border-radius: 15px;
}



.filter-checkbox input,
.filter-checkbox-desktop input {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .filter-checkbox {
    margin-left: 10px;
    font-size: 0.6rem;
    gap: 10px;
  }

  .filter-checkbox-desktop {
    display: none;
  }
}
</style>
