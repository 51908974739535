<template>
  <div class="full-width-background">
    <div class="separator"></div>
    <div class="container"
  :class="{'container-transportations': (currentStep === 'selectTransportations' || currentStep === 'selectHotels' || currentStep === 'selectActivities' || currentStep === 'selectHotelDetails' || currentStep === 'sumUp')}">
      <div class="icon-bar">
        <img src="@/assets/img/voyage/localisation.png" alt="Icone 1"
          :class="['icon', (currentStep !== 'selectTransportations' && currentStep !== 'selectActivities' && currentStep !== 'selectHotels' && currentStep !== 'selectHotelDetails' ? 'highlighted' : '')]">
        <img src="@/assets/img/voyage/right-arrow.png" alt="Icone 2" class="icon arrow">
        <img src="@/assets/img/voyage/train.png" alt="Icone 2"
          :class="['icon', (currentStep === 'selectTransportations' ? 'highlighted' : '')]">
        <img src="@/assets/img/voyage/right-arrow.png" alt="Icone 2" class="icon arrow">
        <img src="@/assets/img/voyage/lit.png" alt="Icone 3" class="icon"
          :class="['', (currentStep === 'selectHotels' || currentStep === 'selectHotelDetails' ? 'highlighted' : '')]">
        <img src="@/assets/img/voyage/right-arrow.png" alt="Icone 2" class="icon arrow">
        <img src="@/assets/img/voyage/calendar.png" alt="Icone 4" class="icon"
          :class="['', (currentStep === 'sumUp' ? 'highlighted' : '')]">
      </div>


      <SelectDestination
        v-if="currentStep === 'selectDestination'"
        :prenom="firstName"
        :destinations="destinations"
        :isLoading="loadingDestinations"
        @setCityTo="setCityTo"
        @update-step="updateStep"
        @reloadDestinations="handleReloadDestinations"
      />

      <TransportationsMain
        v-else-if="currentStep === 'selectTransportations'" 
        :docRef="docRef"
        :exactDates="exactDates"
        @update-step="updateStep"
      />

      <SelectHotels
        v-else-if="currentStep === 'selectHotels'"
        :docRef="docRef"
        :exactDates="exactDates"
        :cityTo="cityTo"
        @update-step="updateStep"
        @displayHotelDetails="displayHotelDetails"
      />

      <SelectHotelDetails
        v-else-if="currentStep === 'selectHotelDetails'"
        :docRef="docRef"
        :hotelRef="hotelRef"
        @update-step="updateStep"
      />

      <SumUp
        v-else-if="currentStep === 'sumUp'"
        :docRef="docRef"
        @update-step="updateStep"
      />

      <div class="bloc-container" v-else>
        <div class="left-bloc" >
          <SelectName 
            v-if="currentStep === 'selectName'" 
            :prenom="firstName" 
            @informations-soumises="handleInformationsSoumises" 
          />
          <SelectWishes v-if="currentStep === 'selectWishes'"
                :prenom="firstName"
                @update-step="updateStep"
                @updateDestinationData="updateDestinationData" />
          <FillWishes v-if="currentStep === 'fillWishes'" :prenom="firstName" @preferences-submitted="handlePreferencesSubmitted" @update-step="updateStep"/>
          <SelectDates v-if="currentStep === 'selectDates'" :prenom="firstName" @dates-submitted="handleDatesSubmitted" @update-step="updateStep"/>
          <SelectBudgetAndDeparture v-if="currentStep === 'selectBudgetAndDeparture'" @budget-and-departure-submitted="handleBudgetAndDepartureSubmitted" @update-step="updateStep" />
        </div>

        <div class="right-bloc" :style="getRightBlocStyle()">
          <!-- Image supplémentaire centrée dans le right-bloc -->
          <img v-if="currentStep === 'fillWishes'" src="@/assets/img/voyage/preferences1.png" alt="Extra Image" class="extra-image-centered">
          
          <div class="right-bloc-content">
            <div v-if="currentStep === 'selectName'">
              <h2>Bienvenue !</h2>
              <p>Quelques informations sur toi pour commencer</p>
            </div>
            <div v-else-if="currentStep === 'selectWishes'">
              <h2>Où allons-nous ?</h2>
              <p>Alego peut t'aider à trouver une destination adaptée à tes envies si tu ne sais pas encore où tu souhaites aller</p>
            </div>
            <div v-else-if="currentStep === 'fillWishes'">
              <h2>Que souhaites-tu pour ton voyage ?</h2>
              <p>Cite moi tes envies et ce que tu souhaites faire, voir, découvrir, visiter ... pendant tes vacances !</p>
            </div>
            <div v-else-if="currentStep === 'selectDates'">
              <h2>Choisis tes Dates</h2>
              <p>Si tu ne connais pas les dates exactes auxquelles tu veux partir, indique la période et la durée pour laquelle tu souhaites voyager</p>
            </div>
            <div v-else-if="currentStep === 'selectBudgetAndDeparture'">
              <h2>Budget et ville de départ</h2>
              <p>Indique d'où tu souhaites partir et ton budget approximatif par personne pour le transport et l'hébergement</p>
            </div>
            <div v-else>
              <h2>Étape Inconnue</h2>
              <p>Veuillez vérifier votre progression.</p>
            </div>
          </div>
        </div>


      </div>


    </div>
  </div>
</template>



<script>
import SelectName from './ChatComponents/SelectName.vue';
import SelectWishes from './ChatComponents/SelectWishes.vue';
import FillWishes from './ChatComponents/FillWishes.vue';
import SelectDates from './ChatComponents/SelectDates.vue';
import SelectBudgetAndDeparture from './ChatComponents/SelectBudgetAndDeparture.vue';
import SelectDestination from './ChatComponents/SelectDestination.vue';
import TransportationsMain from './TransportationsMain.vue';
import SelectHotels from './HotelMain.vue';
import SelectHotelDetails from './HotelDetails.vue';
import SumUp from './SumUp.vue';
import { creerDocumentFirestore, addTripIdToUser } from '../functions/storeInformations';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
  name: 'ChatComponent',
  components: {
    SelectName,
    SelectWishes,
    FillWishes,
    SelectDates,
    SelectBudgetAndDeparture,
    SelectDestination,
    TransportationsMain,
    SelectHotels,
    SelectHotelDetails,
    SumUp
  },
  data() {
    return {
      currentStep: 'selectName',
      firstName: '',
      age: '',
      preferences: [],
      budget: null,
      departureDate: '',
      returnDate: '',
      duration: null,
      exactDates: null,
      explicitGeographicZone: null,
      explicitCity: 0,
      geographicZone: null,
      CountryDestination: null,
      destination: null,
      destinations: [], 
      loadingDestinations: false,
      nbPerson: null,
      cityFrom: '',
      longitudeFrom: '',
      latitudeFrom: '',
      cityTo: '',
      longitudeTo: '',
      latitudeTo: '',
      docRef: null,
      attemptCount: 0,
      hotelRef:'',
      isLinkedToUser: null,
      isConnected: false, 
    };
  },
  methods: {
    handleInformationsSoumises(informations) {
      this.firstName = informations.prenom;
      this.nbPerson = informations.nombrePersonnes;
      this.age = informations.age;
      this.currentStep = 'selectWishes';
    },
    updateStep(step) {
      console.log("Mise à jour de l'étape vers:", step);
      this.currentStep = step;
      this.$forceUpdate();
      
      // Scroll vers le haut de la page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optionnel, pour un défilement fluide
      });
    },

    handlePreferencesSubmitted(data) {
      this.preferences = data.preferences;
      this.currentStep = 'selectDates'; 
    },
    handleDatesSubmitted(data) {
      this.departureDate = data.departureDate;
      this.returnDate = data.returnDate;
      this.exactDates = data.exactDates; 
      this.duration = data.duration !== undefined ? data.duration : null; 
      this.currentStep = 'selectBudgetAndDeparture';
    },

    async handleBudgetAndDepartureSubmitted(data) {
      this.cityFrom = data.cityFrom;
      this.budget = data.budget;
      this.latitudeFrom = data.latitudeFrom;
      this.longitudeFrom = data.longitudeFrom;
      console.log("Toutes les infos : ", this.cityFrom,this.exactDates,this.departureDate,this.returnDate,this.duration,this.preferences,this.budget);
      console.log("Geographic Zone : ", this.geographicZone);
      if (this.explicitCity === 0) {
        this.loadingDestinations = true; // Activez l'indicateur de chargement
        this.currentStep = 'selectDestination'; // Changez l'étape
        await this.appelerFindDestinations();  // Lancez l'appel asynchrone
        this.loadingDestinations = false; // Désactivez l'indicateur de chargement après chargement des données
      }
      else {
        this.loadingDestinations = true;
        console.log("Destination : ", this.destination);
        console.log("Country Destination : ", this.CountryDestination);
        await this.appelerCityInfos();
        this.loadingDestinations = false;
        if (!this.loadingDestinations) {
          await this.saveTripData();
        }
      }
    },
    async handleReloadDestinations() {
      this.isLoading = true; // Activez le chargement pendant la mise à jour des destinations
      try {
        await this.appelerFindDestinations(); // Attendez que l'appel à appelerFindDestinations() soit terminé
        this.isLoading = false; // Désactivez le chargement une fois la mise à jour terminée
      } catch (error) {
        console.error('Erreur lors de la récupération des destinations :', error);
        this.isLoading = false; // Assurez-vous de désactiver le chargement en cas d'erreur
      }
    },
    async appelerFindDestinations() {
      try {
          let requestBody = {
            villeDepart: this.cityFrom,
            datesExactes: this.exactDates,
            datesDepart: this.departureDate,
            datesRetour: this.returnDate,
            dureeVoyage: this.duration,
            preferences: this.preferences,
            budget: this.budget,
            nbPerson: this.nbPerson
          };

          if (this.geographicZone !== null) {
            requestBody.geographicZone = this.geographicZone;
          }

          const response = await fetch('https://europe-west1-alegotravel.cloudfunctions.net/api/findDestinationsBis', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
          });

          if (!response.ok) {
              throw new Error('Erreur réseau ou côté serveur');
          }

          const data = await response.json();
          this.destinations = data; // Mettre à jour les destinations avec les données reçues
          console.log("Contenu de destinations : ", this.destinations)
          if (this.destinations.length === 0 && this.attemptCount < 3) {
              this.attemptCount++;
              await this.appelerFindDestinations(); // Tentative répétée
          }
      } catch (error) {
          console.error("Erreur lors de l'appel à findDestinations:", error);
          alert("Une erreur est survenue lors de la recherche de destinations.");
      }
    },

    async appelerCityInfos() {
      try {
        const response = await fetch('https://europe-west1-alegotravel.cloudfunctions.net/api/cityInfos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            city: this.destination,
            country: this.CountryDestination
          }),
        });
        if (!response.ok) {
          throw new Error('Erreur réseau ou côté serveur');
        }
        const data = await response.json();
        this.longitudeTo = data.longitude;
        this.latitudeTo = data.latitude; 
        this.cityTo = this.destination;
        console.log("Resultats de cityInfo : ", data)
        if (isNaN(parseFloat(this.longitudeTo)) && this.attemptCount < 3) {
            this.attemptCount++;
            await this.appelerCityInfos; // Tentative répétée
        }
      } catch (error) {
        console.error("Erreur lors de l'appel à citiesInfo:", error);
        alert("Une erreur est survenue lors de la validation de toutes infos.");
      }
    },
    
    updateDestinationData(data) {
      console.log('Data reçue pour mise à jour:', data);
      this.explicitGeographicZone = data.explicitGeographicZone;
      this.explicitCity = data.explicitCity;
      this.geographicZone = data.geographicZone; // Ajout pour vérification
      this.CountryDestination = data.CountryDestination;
      this.destination = data.destination;
    },

    async saveTripData() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        const tripData = {
          firstName: this.firstName,
          age: this.age,
          preferences: this.preferences,
          budget: this.budget,
          departureDate: this.departureDate,
          returnDate: this.returnDate,
          duration: this.duration,
          exactDates: this.exactDates,
          destinations: this.destinations,
          nbPerson: this.nbPerson,
          cityFrom: this.cityFrom,
          longitudeFrom: this.longitudeFrom,
          latitudeFrom: this.latitudeFrom,
          cityTo: this.cityTo,
          longitudeTo: this.longitudeTo,
          latitudeTo: this.latitudeTo,
          countryTo: this.CountryDestination,
        };

        if (user) {
          tripData.userId = user.uid; // Ajouter l'ID de l'utilisateur seulement si connecté
        }

        console.log("Trip data : ", tripData);
        const docId = await creerDocumentFirestore(tripData);
        console.log(`Document Tripdata créé avec succès avec l'id suivant : ${docId}`);
        this.docRef = docId;

        if (user) {
          await addTripIdToUser(user.uid, docId); // Ajouter le tripId à l'utilisateur seulement si connecté
        }

        this.updateStep('selectTransportations');
      } catch (error) {
        console.error('Erreur lors de la création du document:', error);
        alert('Une erreur est survenue lors de la création du votre voyage. Veuillez réessayer !');
      }
    },

    setCityTo(data) {
      this.cityTo = data.cityTo,
      this.latitudeTo = data.latitudeTo,
      this.longitudeTo = data.longitudeTo,
      //console.log("Datas :", this.cityTo, this.latitudeTo)
      this.saveTripData();
    },
    updateDestinations(list) {
      this.destinations = list;
    },
    displayHotelDetails(hotelRef) {
      console.log("Hotel Ref avant affectation : ", hotelRef);
      if (hotelRef) {
        this.hotelRef = hotelRef;
        console.log("Hotel Ref : ", this.hotelRef);
        console.log("Doc Ref avant changement de page : ", this.docRef);
      } else {
        console.log("hotelRef est manquant");
      }
    },
    async checkUserConnection() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.isConnected = true;
          await this.fetchUserFirstName(user.uid); // Récupérez le prénom de l'utilisateur connecté

          // Si docRef a une valeur et que l'utilisateur vient de se connecter, ajoutez-le à tripIds
          if (this.docRef) {
            await this.addTripIdToUser(user.uid, this.docRef);
          }
        } else {
          this.isConnected = false;
        }
      });
    },
    async fetchUserFirstName(userId) {
      const db = getFirestore();
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          this.firstName = userDoc.data().firstName; // Définir le prénom de l'utilisateur connecté
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
      }
    },
    getRightBlocStyle() {
      switch (this.currentStep) {
        case 'selectName':
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/welcome.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
        case 'selectWishes':
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/preferences.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
        case 'fillWishes':
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/preferences.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
        case 'selectDates':
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/dates.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
        case 'selectBudgetAndDeparture':
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/budget.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
        default:
          return {
            backgroundImage: `url(${require('@/assets/img/voyage/camping.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          };
      }
    },
    async addTripIdToUser(userId, tripId) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userTripIds = userDoc.data().tripIds || [];
          userTripIds.push({ tripId: tripId, dateAdded: new Date() }); // Ajoutez l'ID du voyage et la date
          await updateDoc(userDocRef, {
            tripIds: userTripIds
          });
          console.log(`TripId ${tripId} ajouté à l'utilisateur ${userId}`);
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Erreur lors de l\'ajout de tripId à l\'utilisateur:', error);
      }
    },
  },
  mounted() {
    this.checkUserConnection(); // Vérifie la connexion de l'utilisateur lors du montage du composant
  }
};
</script>



<style scoped>


.arrow {
  height: 15px;
}

.container {
  width: 100%;
  margin: 0 auto;
  color: var(--dark-gray);
}

.bloc-container {
  display: flex;
  margin:  0;
  height: 82vh;
}

.left-bloc {
  flex: 1;
  background-color: white;
  height: 100%;
  padding: 30px 60px 30px 30px;
}

.right-bloc {
  position: relative;
  flex: 1;
  height: 100%;
  max-width: 45%;
  /*background: url('@/assets/img/voyage/camping.jpg') center center / cover no-repeat;*/
  overflow: hidden;
}

.right-bloc-content {
  position: absolute;
  top: 30px; /* Ajustez la position verticale */
  left: 0px; /* Ajustez la position horizontale */
  background: rgba(255, 255, 255, 0.8); /* Fond blanc semi-transparent */
  padding: 10px 20px; /* Ajustez le padding selon vos besoins */
  border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 75%;
}

.right-bloc-content h2 {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--jaune); /* Couleur du texte */
}

.right-bloc-content p {
  margin: 0;
  font-size: 14px;
  color: #555; /* Couleur du texte */
}


.left-bloc, .right-bloc {
  box-sizing: border-box; 
  box-shadow: 0 6px 12px rgba(0,0,0,0.1); /* Ajout d'une ombre pour un effet de relief */
}

.container-transportations {
  height: auto !important;
  min-height: 80%;
  width: 100%;
  margin: 0 auto;
}

.extra-image-centered {
  position: absolute;
  top: 65%; /* Centrer verticalement */
  left: 50%; /* Centrer horizontalement */
  transform: translate(-50%, -50%); /* Ajuster pour centrer exactement */
  width: 100%; 
  max-width: 100%;
  height: auto; 
  z-index: 5; /* S'assurer que l'image est au-dessus du contenu de fond */
}
/* Ajoutez ce bloc dans votre CSS existant */

/* Media query pour responsive */
@media (max-width: 768px) {
  /* Cache les images avec la classe 'arrow' en mode responsive */
  .arrow {
    display: none;
  }
  .right-bloc {
    display: none;
  }

  .left-bloc {
    border-radius: 0px;
    box-shadow: 0 0px 0px rgba(0,0,0,0.1); /* Ajout d'une ombre pour un effet de relief */
    padding: 0 10px;
    background-color: #f0f0f0;
    height: auto;
  }

  .bloc-container {
    margin: 0;
    height: auto;
  }

  .container {
    min-height: 30vh;
    width: 100%;
    margin: 0vh auto 5vh auto;
    padding-top: 3vh;
  }

  .container-transportations {
    width: 100%;
  }

  .icon-bar {
    width: 90%;
    margin: auto;
  }
}


</style>
