<template>
  <div id="app">
    <SiteHeader v-if="!isAccueilPage" />
    <router-view />
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import { useHead } from '@vueuse/head';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  },
  computed: {
    isAccueilPage() {
      return this.$route.name === 'Accueil'
    }
  },
  setup() {
    useHead({
      title: 'Alego: Planifiez vos voyages personnalisés grâce à l’IA',
      meta: [
        {
          name: 'description',
          content: 'Planifiez en quelques clics vos voyages personnalisés avec l’IA. Entrez vos centres d\'intérêts, budget et dates pour trouver les meilleures destinations. Comparez les vols, trains, hôtels et activités sur une seule plateforme pour créer le voyage sur mesure idéal. Avec Alego, planifiez facilement votre prochain séjour dès maintenant !'
        }
      ]
    });
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index:0;
  background-color: var(--light-gray);
}

router-view {
  flex: 1;
}

</style>
