<template>
  <div class="page-container">
    <div class="trips-container">
      <div class="trips-title">
        <img src="@/assets/img/profile/valise.png" alt="Voyages" class="title-icon"/>
        <h2>MES VOYAGES</h2>
      </div>

      <hr />

      <div v-if="loading">
        <p>Loading trips...</p>
      </div>
      <div v-else>
        <div v-if="trips.length === 0">
          <p>Vous n'avez pas de voyages pour le moment.</p>
        </div>
        <div v-else>
          <div v-for="trip in trips" :key="trip.id" class="trip-card">
            <div class="trip-header">
              <div class="trip-info">
                <h3>{{ trip.cityTo }}</h3>
                <p>{{ getDisplayDates(trip) }}</p>
              </div>
              <div class="trip-icons">
                <img :src="getIconSrc(true, 'location')" alt="Location Icon" class="icon completed" />
                <img :src="getIconSrc(trip.selectedFlight || trip.selectedGroundTransportation, 'train')" alt="Transport Icon" :class="['icon', { completed: trip.selectedFlight || trip.selectedGroundTransportation }]" />
                <img :src="getIconSrc(trip.selectedHotel, 'hotel')" alt="Hotel Icon" :class="['icon', { completed: trip.selectedHotel }]" />
                <img :src="getIconSrc(trip.activityDocumentId, 'kayak')" alt="Activity Icon" :class="['icon', { completed: trip.activityDocumentId }]" />
              </div>
              <span class="expand-icon" @click="toggleExpand(trip.id)">
                {{ trip.expanded ? '▲' : '▼' }}
              </span>
              <button class="delete-button" @click="confirmDelete(trip.id)">X</button> <!-- Bouton Supprimer -->
            </div>
            <!-- Pop-up de confirmation -->
            <div v-if="showDeletePopup && tripToDelete === trip.id" class="delete-popup">
              <p>Voulez-vous vraiment supprimer ce voyage ?</p>
              <button @click="deleteTrip(trip.id)">Confirmer</button>
              <button @click="cancelDelete">Annuler</button>
            </div>
            <div v-if="trip.expanded" class="trip-actions">
              <button 
                class="choose-button" 
                @click="modifyTransport(trip.id)">
                {{ trip.selectedFlight || trip.selectedGroundTransportation ? 'Modifier le transport' : 'Choisir un moyen de transport' }}
              </button>
              <button 
                class="choose-button" 
                @click="modifyHotel(trip.id)">
                {{ trip.selectedHotel ? 'Modifier l\'hôtel' : 'Choisir un hôtel' }}
              </button>
              <div v-if="trip.selectedHotel" class="trip-action-buttons">
                <button 
                  class="choose-button" 
                  @click="trip.activityDocumentId ? regenerateActivityList(trip.id, trip.activityDocumentId) : createActivityPlan(trip.id)">
                  {{ trip.activityDocumentId ? 'Regénérer une liste d\'activités' : 'Créer le planning d\'activités' }}
                </button>
                <button 
                  v-if="trip.activityDocumentId"
                  class="choose-button" 
                  @click="modifyActivityPlan(trip.id, trip.activityDocumentId)">
                  Modifier le planning d'activités
                </button>
                <button 
                  class="choose-button" 
                  @click="viewTripDetails(trip.id)">
                  Détails du voyage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { db } from '@/firebase';
import { getUserTrips } from '@/functions/getUserInfos.js';

export default {
  name: 'UserTrips',

  data() {
    return {
      trips: [],
      user: null,
      loading: true,
      showDeletePopup: false,
    };
  },
  async created() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          this.user = {
            uid: user.uid,
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || user.email,
            photoURL: userData.photoURL || user.photoURL
          };
        } else {
          this.user = {
            uid: user.uid,
            firstName: '',
            lastName: '',
            email: user.email,
            photoURL: user.photoURL
          };
        }

        // Retrieve trips for the authenticated user
        try {
          const userId = this.user.uid;
          const tripsData = await getUserTrips(userId);

          // Check for activityDocumentId for each trip
          for (const trip of tripsData) {
            const tripRef = doc(db, "tripdatas", trip.id);
            const tripSnapshot = await getDoc(tripRef);
            if (tripSnapshot.exists()) {
              const tripData = tripSnapshot.data();
              trip.activityDocumentId = tripData.activitiesDocumentId || null;
              //console.log("activityId", tripData.activitiesDocumentId)
              //console.log("activityId", trip.activityDocumentId)
            } else {
              trip.activityDocumentId = null;
            }
          }

          this.trips = tripsData.map(trip => ({
            ...trip,
            expanded: false // Initialize expanded state
          }));
        } catch (error) {
          console.error("Erreur lors de la récupération des voyages:", error);
        } finally {
          this.loading = false;
        }
      } else {
        this.user = null;
        this.$router.push('/auth');
      }
    });
  },
  methods: {
    confirmDelete(tripId) {
      this.tripToDelete = tripId;
      this.showDeletePopup = true;
    },
    cancelDelete() {
      this.tripToDelete = null;
      this.showDeletePopup = false;
    },
    async deleteTrip(tripId) {
      try {
        if (this.user) {
          const userRef = doc(db, 'users', this.user.uid);
          
          // Récupérez le document utilisateur pour trouver l'objet à supprimer
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            
            // Trouvez l'objet à supprimer en cherchant par tripId
            const tripObjectToRemove = userData.tripIds.find(trip => trip.tripId === tripId);
            
            if (tripObjectToRemove) {
              // Utilisez arrayRemove pour supprimer l'objet
              await updateDoc(userRef, {
                tripIds: arrayRemove(tripObjectToRemove)
              });

              // Mettre à jour la liste locale après suppression
              this.trips = this.trips.filter(trip => trip.id !== tripId);
              this.showDeletePopup = false;
              this.tripToDelete = null;

              console.log("Voyage supprimé avec succès.");
            } else {
              console.error("Le voyage à supprimer n'a pas été trouvé dans tripIds.");
            }
          } else {
            console.error("Document utilisateur introuvable.");
          }
        }
      } catch (error) {
        console.error("Erreur lors de la suppression du voyage :", error);
      }
    },
    parseDate(date) {
      if (typeof date === 'string' || date instanceof String) {
        return new Date(date);
      } else if (date instanceof Date) {
        return date;
      } else if (date && typeof date.toDate === 'function') {
        return date.toDate();
      } else {
        return new Date(date); // Fallback to creating a new Date object
      }
    },
    formatDates(date) {
      const options = { weekday: 'short', day: 'numeric', month: 'short' };
      const parsedDate = this.parseDate(date);
      return parsedDate.toLocaleDateString('fr-FR', options);
    },
    getDuration(startDate, endDate) {
      const start = this.parseDate(startDate);
      const end = this.parseDate(endDate);
      const duration = Math.round((end - start) / (1000 * 60 * 60 * 24));
      return duration;
    },
    getDisplayDates(trip) {
        if (trip.exactDates) {
          return this.formatDates(trip.departureDate) + ' - ' + this.formatDates(trip.returnDate);
        } else if (trip.selectedFlight) {
          return this.formatDates(trip.selectedFlight.departureDate) + ' - ' + this.formatDates(trip.selectedFlight.returnDate);
        } else if (trip.selectedGroundTransportation) {
          return this.formatDates(trip.selectedGroundTransportation.outboundTrip.departureDate) + ' - ' + this.formatDates(trip.selectedGroundTransportation.returnTrip.arrivalDate);
        } else if (trip.duration && trip.duration > 0) {
          return `${trip.duration} jours entre ${this.formatDates(trip.departureDate)} et ${this.formatDates(trip.returnDate)}`;
        } else {
          const duration = this.getDuration(trip.departureDate, trip.returnDate);
          return `${duration} jours entre ${this.formatDates(trip.departureDate)} et ${this.formatDates(trip.returnDate)}`;
        }
    },
    modifyTrip(tripId) {
      this.$router.push({ name: 'ModifyTrip', params: { tripId } }); // Redirige vers la page de modification du voyage
    },
    modifyTransport(tripId) {
      this.$router.push({ name: 'ModifyTransport', params: { tripId } });
    },
    modifyHotel(tripId) {
      this.$router.push({ name: 'ModifyHotel', params: { tripId } });
    },
    createActivityPlan(tripId) {
      this.$router.push({ name: 'ActivitiesPage', params: { tripId } });
    },
    regenerateActivityList(tripId, activityDocumentId) {
      this.$router.push({ name: 'ActivitiesPage', params: { tripId, activitiesDocument: activityDocumentId } });
    },
    modifyActivityPlan(tripId, activityDocumentId) {
      console.log(`Navigating to ModifyActivities with Trip ID: ${tripId} and Activities Document ID: ${activityDocumentId}`);
      this.$router.push({ name: 'ModifyActivities', params: { tripId, activitiesDocument: activityDocumentId } });
    },
    viewTripDetails(tripId) {
      this.$router.push({ name: 'SumUpPage', params: { tripId } });
    },
    getIconSrc(selected, iconName) {
      return selected
        ? require(`@/assets/img/profile/${iconName}.png`)
        : require(`@/assets/img/profile/${iconName}-grey.png`);
    },
    toggleExpand(tripId) {
      const trip = this.trips.find(t => t.id === tripId);
      if (trip) {
        trip.expanded = !trip.expanded;
      }
    }
  }
};


</script>



<style scoped>
:root {
  --gris-clair: #f8f8f8;
  --jaune: #D4A14E;
  --gris: #777;
  --noir: #333;
}

.page-container {
  width: 100%;
  display: flex;
  background-color: var(--gris-clair);
  padding: 20px;
}

.trips-container {
  width: 100%;
  max-width: 1000px;
  border-radius: 10px;
}

.trips-title {
  gap: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}

h2 {
  font-size: 1.5em;
  color: var(--dark-gray);
}

.title-icon {
  width: 50px;
  height: 50px;
}

.trip-card {
  display: flex;
  position: relative;
  color: var(--dark-gray);
  flex-direction: column;
  background-color: white;
  padding: 40px;
  margin-bottom: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.trip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trip-info {
  display: flex;
  flex-direction: column;
}

.trip-card h3 {
  margin: 0;
  font-size: 1.2em;
  color: var(--dark-gray);
}

.trip-card p {
  margin: 5px 0 0;
  color: var(--gris);
  font-size: 0.9em;
}

.trip-icons {
  display: flex;
  gap: 30px;
  margin-left: auto;
  margin-right: 20%;
}

.icon {
  width: 24px;
  height: 24px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.icon.completed {
  opacity: 1;
}

.trip-actions {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.choose-button {
  background: var(--jaune);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background 0.3s;
}

.choose-button:hover {
  background: #c39441;
}

.trip-action-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.trips-container hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px 0 40px 0;
}

.expand-icon {
  font-size: 1.5em;
  cursor: pointer;
  color: var(--jaune);
  margin-left: 10px; /* Ensure equal spacing */
  transition: color 0.3s;
  margin-right: 25px;
}

.expand-icon:hover {
  color: #c39441;
}

@media (max-width: 768px) {
  .trips-container {
    padding: 0 10px;
    width: 100%;
  }

  .page-container {
    width: 100%;
    padding: 0
  }

  .trips-container hr {
    margin: 10px 10px 20px 10px;
  }

  h2 {
    font-size: 1rem;
  }

  .trips-title {
    margin-left: 20px;
  }

  .title-icon {
    width: 40px;
    height: 40px;
  }

  .trip-card {
    padding: 20px;
  }

  .trip-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .trip-info {
    align-items: flex-start;
  }

  .trip-info h3 {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .trip-info p {
    margin-bottom: 20px;
    font-size: 0.8rem
  }

  .trip-icons {
    justify-content: center;
    margin: 10px 0;
    width: 100%;
  }

  .expand-icon {
    align-self: center;
    margin-left: 0
  }

  .choose-button {
    margin: 0;
    font-size: 0.8rem;
  }
}

.delete-button {
  position: absolute;
  top: 0px;
  right: 0px;
  color: var(--jaune);
  background-color: white;
}


.delete-button:hover {
  color: var(--vert);
}

.delete-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.delete-popup button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-popup button:first-child {
  background-color: #d9534f;
  color: white;
}

.delete-popup button:last-child {
  background-color: #f0f0f0;
  color: #333;
}

</style>
