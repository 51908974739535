import { createRouter, createWebHistory } from 'vue-router';

import AccueilPage from '@/views/AccueilPage.vue';
import VoyagePage from '@/views/VoyagePage.vue';
import UserAuth from '@/views/UserAuth.vue';
import ProfileContent from '@/views/ProfileContent.vue';
import UserInformations from '@/views/profile/UserInformations.vue';
import UserTrips from '@/views/profile/UserTrips.vue';
import UserFavorites from '@/views/profile/UserFavorites.vue';
import UserTravelerProfile from '@/views/profile/UserTravelerProfile.vue';
import ModifyTrip from '@/views/profile/ModifyTrip.vue'; 
import ActivitiesPage from '@/views/ActivitiesPage.vue'; 
import ModifyTransport from '@/views/ModifyTransport.vue'; 
import ModifyHotel from '@/views/ModifyHotel.vue'; 
import SumUpPage from '@/views/SumUpPage.vue'; 
import ModifyActivities from '@/views/ModifyActivities.vue'; 
import AboutUs from '@/views/AboutUs.vue';


const routes = [
  { path: '/', name: 'Accueil', component: AccueilPage },
  { path: '/voyage', name: 'Voyage', component: VoyagePage },
  { path: '/auth', name: 'UserAuth', component: UserAuth },
  { path: '/profile', name: 'ProfileContent', component: ProfileContent, 
    children: [
      { path: 'informations', name: 'UserInformations', component: UserInformations },
      { path: 'trips', name: 'UserTrips', component: UserTrips },
      { path: 'trips/modify/:tripId', name: 'ModifyTrip', component: ModifyTrip, props: true },
      { path: 'favorites', name: 'UserFavorites', component: UserFavorites },
      { path: 'traveler-profile', name: 'UserTravelerProfile', component: UserTravelerProfile }
    ] 
  },
  {
    path: '/modifyActivities/:tripId/:activityDocument?',
    name: 'ModifyActivities',
    component: ModifyActivities,
    props: true
  },
  {
    path: '/activities/:tripId/:activitiesDocument?',
    name: 'ActivitiesPage',
    component: ActivitiesPage,
    props: true
  },
  {
    path: '/modifyActivities/:tripId/:activitiesDocument',
    name: 'ModifyActivities',
    component: ModifyActivities,
    props: true
  },
  { path: '/voyage/modifyTransport/:tripId', name: 'ModifyTransport', component: ModifyTransport, props: true },
  { path: '/voyage/modifyHotel/:tripId', name: 'ModifyHotel', component: ModifyHotel, props: true },
  { path: '/sumUp/:tripId', name: 'SumUpPage', component: SumUpPage, props: true },
  { path: '/about', name: 'AboutUs', component: AboutUs, props: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Restaure la position si l'utilisateur utilise les boutons "précédent" et "suivant"
    } else {
      return { top: 0 }; // Défile en haut de la page à chaque changement de route
    }
  },
});

export default router;
