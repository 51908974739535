<template>
  <div class="section-fonctionnement">
    <h2 class="title">Le fonctionnement</h2>
    <div class="carousel-container">
      <div class="carousel">
        <div v-show="currentSlide === 0" class="carousel-item">
          <div class="slide-content">
            <div class="slide-image">
              <img src="@/assets/img/landing/caroussel1.png" alt="Titre du Slide 1">
            </div>
            <div class="slide-text">
              <h3>Ecrivez vos critères de voyage</h3>
              <p>Décrivez vos critères de voyage comme vos centres d’intérêts, vos dates, votre budget afin de cocréer le voyage parfait !</p>
            </div>
          </div>
        </div>
        <div v-show="currentSlide === 1" class="carousel-item">
          <div class="slide-content">
            <div class="slide-image">
              <img src="@/assets/img/landing/caroussel2.png" alt="Titre du Slide 2">
            </div>
            <div class="slide-text">
              <h3>Choisissez votre destination</h3>
              <p>Grâce à vos critères, nous vous proposons des destinations, choisissez celle qui vous plaît le plus !</p>
            </div>
          </div>
        </div>
        <div v-show="currentSlide === 2" class="carousel-item">
          <div class="slide-content">
            <div class="slide-image">
              <img src="@/assets/img/landing/caroussel3.png" alt="Titre du Slide 3">
            </div>
            <div class="slide-text">
              <h3>Sélectionnez votre transport et votre hébergement</h3>
              <p>Accédez à un large choix de transports et d’hébergements afin que votre voyage vous corresponde vraiment !</p>
            </div>
          </div>
        </div>
        <div v-show="currentSlide === 3" class="carousel-item">
          <div class="slide-content">
            <div class="slide-image">
              <img src="@/assets/img/landing/caroussel4.png" alt="Titre du Slide 4">
            </div>
            <div class="slide-text">
              <h3>Planifiez vos activités</h3>
              <p>Prenez de l’avance et organisez vos activités à l’avance grâce à notre planificateur intégré !</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Navigation Buttons Wrapper -->
      <div class="carousel-controls-wrapper">
        <button class="carousel-control left" @click="prevSlide">❮</button>
        <button class="carousel-control right" @click="nextSlide">❯</button>
      </div>
    </div>
    <div class="carousel-indicators">
      <span v-for="index in 4" :key="index" class="indicator" :class="{ 'active': index - 1 === currentSlide }" @click="goToSlide(index - 1)"></span>
    </div>
  </div>
</template>



<script>
export default {
  name: 'AccueilStep2',
  data() {
    return {
      currentSlide: 0
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % 4;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + 4) % 4;
    },
    goToSlide(index) {
      this.currentSlide = index;
    }
  }
}
</script>


<style scoped>

.section-fonctionnement {
  background-color: var(--light-gray);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 80px 0;
}

.title {
  color: var(--vert);
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 90%;
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  height: fit-content;
}

.carousel-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.slide-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 50px;
  margin-top: -50px;
  margin-bottom: -50px;
}

.slide-image {
  position: relative;
}

.slide-image img {
  width: 100%;
  max-width: 80vh;
  border-radius: 10px;
}

.slide-text {
  margin-right: 50px;
  margin-left: -40px;
  color: var(--dark-gray);
  width: 45%;
  z-index: 2;
}

.carousel-controls-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Allows clicks to pass through */
}

.carousel-control {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
  color: var(--jaune);
  pointer-events: auto; /* Overrides the wrapper's pointer-events */
}

.carousel-control.left {
  left: 0;
}

.carousel-control.right {
  right: 0;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: var(--jaune);
  margin: 0 5px;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.3s;
  cursor: pointer;
}

.indicator.active {
  opacity: 1;
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
  .carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    position: relative;
  }

  .carousel-controls-wrapper {
    position: static;
    margin-top: 20px;
    transform: none;
    top: auto;
  }

  .carousel-control {
    font-size: 2rem;
    cursor: pointer;
  }

  .carousel {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    align-items: center;
  }

  .carousel-item {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
  }

  .slide-image {
    width: 100%;
    text-align: center;
    margin-bottom: -35px;
  }

  .slide-image img {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }

  .slide-text {
    text-align: center;
    color: var(--dark-gray);
    width: 100%;
    margin: 0 auto 15px auto !important;
  }

  .slide-text h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .slide-text p {
    font-size: 0.8rem;
    margin: 20px 10px 40px 10px;
  }

  .carousel-control {
    margin-top: 0;
  }
  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: -40px;
  }

  .indicator {
    width: 10px;
    height: 10px;
    background-color: var(--jaune);
    margin: 0 5px;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
  }

  .indicator.active {
    opacity: 1;
  }

  .title {
    margin-top: 0;
    font-size: 1.5rem;
  }
}


</style>
