<template>
  <div>
    <SiteHeaderLanding />
    <div class="header-section">
      <div class="header-overlay">
        <img src="@/assets/img/landing/landing.png" alt="Landing Background" class="background-image">
        <div class="gradient-overlay"></div>
        <div class="content">
          <div class="hero-text">
            <h1>Co-créez votre <span>voyage sur-mesure</span> avec Alego</h1>
            <p>Construisez rapidement et simplement le séjour qui VOUS ressemble vraiment</p>
          </div>
          <div class="cta-header">  
            <router-link to="/voyage">
              <button class="cta-button">Créer un voyage</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteHeaderLanding from '@/components/SiteHeaderLanding.vue';

export default {
  name: 'AccueilStep1',
  components: {
    SiteHeaderLanding
  }
}
</script>

<style scoped>
.header-section {
  position: relative;
  height: 90vh; /* Ajuste la hauteur à 80vh */
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;
}

.header-section::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 100px;
    background: var(--light-gray);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 54.67%, 2.00% 54.12%, 4.00% 52.52%, 6.00% 49.94%, 8.00% 46.51%, 10.00% 42.42%, 12.00% 37.86%, 14.00% 33.07%, 16.00% 28.29%, 18.00% 23.77%, 20.00% 19.73%, 22.00% 16.39%, 24.00% 13.92%, 26.00% 12.43%, 28.00% 12.01%, 30.00% 12.67%, 32.00% 14.39%, 34.00% 17.07%, 36.00% 20.58%, 38.00% 24.74%, 40.00% 29.34%, 42.00% 34.14%, 44.00% 38.90%, 46.00% 43.38%, 48.00% 47.34%, 50.00% 50.59%, 52.00% 52.96%, 54.00% 54.34%, 56.00% 54.64%, 58.00% 53.86%, 60.00% 52.03%, 62.00% 49.25%, 64.00% 45.65%, 66.00% 41.44%, 68.00% 36.80%, 70.00% 31.99%, 72.00% 27.25%, 74.00% 22.82%, 76.00% 18.93%, 78.00% 15.76%, 80.00% 13.50%, 82.00% 12.24%, 84.00% 12.06%, 86.00% 12.96%, 88.00% 14.90%, 90.00% 17.78%, 92.00% 21.45%, 94.00% 25.73%, 96.00% 30.39%, 98.00% 35.21%, 100.00% 39.93%);
}

.header-overlay {
  position: relative;
  height: 100%;
  width: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, var(--vert), var(--jaune));
  opacity: 0.8; /* Ajuste l'opacité selon vos besoins */
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centre verticalement le contenu */
  align-items: center; /* Centre horizontalement le contenu */
  text-align: center;
  color: white;
  padding: 20px;
}

.hero-text {
  width: 55%;
  text-align: left;
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.hero-text h1 {
  font-size: 2.3rem; /* Augmente la taille de la police pour correspondre au design */
  margin: 0;
  line-height: 1.2; /* Ajuste l'interligne */
  width: 80%;
}

.hero-text span {
  color: var(--jaune);
}

.hero-text p {
  font-size: 1.5rem; /* Augmente la taille de la police pour correspondre au design */
  margin: 20px 0; /* Ajuste la marge */
  width: 80%;
}

.cta-header {
  width: 35%;
}

.cta-button {
  background-color: var(--white);
  color: var(--jaune);
  border: none;
  padding: 25px 35px; /* Augmente la taille du bouton */
  font-size: 1.5rem; /* Augmente la taille de la police */
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Ajoute une transition pour l'effet au survol */
}

.cta-button:hover {
  background-color: var(--jaune);
  color: var(--white);
}

@media (max-width: 768px) {
  .gradiant-overlay, .background-image {
    display: none;
  }

  .header-section {
    background: linear-gradient(135deg, var(--vert), var(--jaune));
    height: 80vh;
  }

  .header-section::after {
   clip-path: polygon(100% 100%, 0% 100% , 0.00% 60.00%, 2.00% 59.75%, 4.00% 58.99%, 6.00% 57.78%, 8.00% 56.18%, 10.00% 54.26%, 12.00% 52.12%, 14.00% 49.87%, 16.00% 47.64%, 18.00% 45.52%, 20.00% 43.63%, 22.00% 42.06%, 24.00% 40.90%, 26.00% 40.20%, 28.00% 40.00%, 30.00% 40.31%, 32.00% 41.12%, 34.00% 42.38%, 36.00% 44.02%, 38.00% 45.97%, 40.00% 48.13%, 42.00% 50.38%, 44.00% 52.61%, 46.00% 54.71%, 48.00% 56.57%, 50.00% 58.09%, 52.00% 59.20%, 54.00% 59.85%, 56.00% 59.99%, 58.00% 59.62%, 60.00% 58.76%, 62.00% 57.46%, 64.00% 55.78%, 66.00% 53.80%, 68.00% 51.63%, 70.00% 49.37%, 72.00% 47.15%, 74.00% 45.07%, 76.00% 43.25%, 78.00% 41.76%, 80.00% 40.70%, 82.00% 40.11%, 84.00% 40.03%, 86.00% 40.45%, 88.00% 41.36%, 90.00% 42.71%, 92.00% 44.43%, 94.00% 46.44%, 96.00% 48.62%, 98.00% 50.88%, 100.00% 53.09%);
  }

  .hero-text {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .hero-text h1{
    font-size: 1.5rem;
  }

  .hero-text p {
    font-size: 1.1rem;
  }

  .content {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .cta-header {
    width: 100%;
  }

  .cta-button {
    padding: 15px 20px;
  }
}
</style>
