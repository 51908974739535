<template>
  <div class="transportation-container">
    <div v-if="loading" class="loading-container2">
      <div class="loading-content2">
        <img src="@/assets/loading-gif.gif" style="height: 150px;" alt="Chargement...">
      </div>
    </div>

    <div class="transportation-main-container" v-else>

      <div class="dates-and-choice">
        
        <div class="dates-picker">
          <h2>Changer les dates du voyage</h2>
          <div class="date-picker-container">
            <div class="calendars">
              <el-date-picker
                v-model="departureDate"
                type="date"
                placeholder="Départ"
                format="YYYY-MM-DD"
              ></el-date-picker>
              <el-date-picker
                v-model="returnDate"
                type="date"
                placeholder="Retour"
                format="YYYY-MM-DD"
              ></el-date-picker>
            </div>
            <button @click="validateDates">Valider</button>
          </div>
        </div>

        <!-- Mobile Date Picker -->
        <div class="dates-picker-mobile">
          <div class="dates-picker-header">
            <h2>Changer les dates</h2>
            <button class="toggle-button" @click="toggleDatePicker">
              <span :class="{'rotate-down-dates': !isDatePickerOpen, 'rotate-up-dates': isDatePickerOpen}">></span>
            </button>
          </div>
          <transition name="slide-down">
            <div v-if="isDatePickerOpen" class="date-picker-container-mobile">
              <div class="calendars">
                <input
                  type="date"
                  v-model="mobileDepartureDate"
                  placeholder="Départ"
                  @change="updateDepartureDate"
                />
                <input
                  type="date"
                  v-model="mobileReturnDate"
                  placeholder="Retour"
                  @change="updateReturnDate"
                />
              </div>
              <button class="validate-button" @click="validateMobileDates">Valider</button>
            </div>
          </transition>
        </div>

        <div class="train-or-flight">
          <div
            class="choice-bloc left"
            :class="{ highlighted: currentTab === 'train' }"
            @click="currentTab = 'train'"
          >
            <img src="@/assets/img/voyage/train-grey.png" alt="Train">
            <p>Train</p>
          </div>
          <div
            class="choice-bloc right"
            :class="{ highlighted: currentTab === 'flight' }"
            @click="currentTab = 'flight'"
          >
            <img src="@/assets/img/voyage/plane-highlighted.png" alt="Plane">
            <p>Avion</p>
          </div>
        </div>

      </div>

      <div v-if="currentTab === 'flight' " class="trip-first-infos">
        <div class="departure-arrival">
          <p>{{ tripData.cityFrom }}</p>
          <img src="@/assets/img/voyage/arrows.png" alt="arrows">
          <p>{{ tripData.cityTo }}</p>
        </div>

        <div class="transportation-boolean">
          <input type="checkbox" id="no-transport" name="no-transport" v-model="noTransport" @change="handleNoTransportChange">
          <label for="no-transport">Pas besoin de transport</label>
        </div>
      </div>

      <div v-if="currentTab === 'train'" class="trip-first-infos">
        <div class="departure-arrival">
          <p>{{ tripData.cityFrom }}</p>
          <img src="@/assets/img/voyage/arrows.png" alt="arrows">
          <p>{{ tripData.cityTo }}</p>
        </div>
        <div class="transportation-boolean">
          <input type="checkbox" id="no-transport" name="no-transport" v-model="noTransport" @change="handleNoTransportChange">
          <label for="no-transport">Pas besoin de transport</label>
        </div>
      </div>

      <div v-if="noTransport" class="no-transport-container">
        <button @click="navigateToHotels" class="navigate-hotels-button">Choisir un hôtel</button>
      </div>

      <!-- FLIGHT - Desktop Layout -->
      <div v-if="currentTab === 'flight'  && !noTransport" class="flight-card-wrapper">
        <div v-if="flights.length === 0" class="no-results">
          <p>Aucun vol trouvé par notre partenaire.</p>
          <p v-if="tripData.exactDates">Vous avez sélectionné des dates précises, des dates différentes pourraient donner des résultats.</p>
        </div>
        <div class="flight-card" v-for="(flight, index) in flights" :key="index" @click="openPopup(flight)">
          <div class="flight-card-left">
            <div class="rows-container">
              <div class="row">
                <div class="way-column">
                  <h3>Aller</h3>
                </div>
                <div class="flight-info">
                  <div class="flight-row">
                    <h3 class="from-to">{{ flight.from }}</h3>
                    <p>{{ flight.departure_airline_name }}</p>
                    <h3 class="from-to-right">{{ flight.to }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ flight.departure_flight_duration }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ flight.departureHour }}</h3>
                      <p>{{ flight.departureDateDisplay }}</p>
                    </div>
                    <p>{{ flight.correspondanceAller === 0 ? 'Direct' : flight.correspondanceAller + ' escale' + (flight.correspondanceAller > 1 ? 's' : '') }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ flight.departureHourLanding }}</h3>
                      <p>{{ flight.departureDateLandingDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-separator"></div>
              <div class="row">
                <div class="way-column">
                  <h3>Retour</h3>
                </div>
                <div class="flight-info">
                  <div class="flight-row">
                    <h3 class="from-to">{{ flight.to }}</h3>
                    <p>{{ flight.return_airline_name }}</p>
                    <h3 class="from-to-right">{{ flight.from }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ flight.return_flight_duration }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ flight.returnHourTakeOff }}</h3>
                      <p>{{ flight.returnDateTakeOffDisplay }}</p>
                    </div>
                    <p>{{ flight.correspondanceRetour === 0 ? 'Direct' : flight.correspondanceRetour + ' escale' + (flight.correspondanceRetour > 1 ? 's' : '') }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ flight.returnHour }}</h3>
                      <p>{{ flight.returnDateDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flight-card-right">
            <div class="text-container">
              <h2>{{ flight.price }}€</h2>
              <h4>par pers.</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- FLIGHT - Fin Desktop Layout -->


      <!-- FLIGHT - PopUp -->
      <div id="flight-popup" class="flight-popup" v-if="showPopup">
        <div class="flight-popup-content">
          <span class="flight-close" @click="closePopup">&times;</span>
          <div class="flight-popup-header">
            <h2>{{ selectedFlight.from }} <span class="flight-arrow">↔</span> {{ selectedFlight.to }}</h2>
            <div class="button-choose" @click="chooseFlight">
              Choisir
            </div>
          </div>
          <div class="flight-dates">
            <div :class="{ 'flight-date': true, 'flight-selected': selectedDate === 'departure' }" @click="selectDate('departure')">Aller</div>
            <div :class="{ 'flight-date': true, 'flight-selected': selectedDate === 'return' }" @click="selectDate('return')">Retour</div>
          </div>

          <div class="flight-selected-date" v-if="(selectedDate === 'departure')">
            <span class="flight-arrow-icon"><img style="margin-bottom: 5px; width: 20px; height: 20px;" src="@/assets/img/voyage/down-arrow-yellow.png" alt="↓"></span> <span class="flight-date-text">{{ selectedFlight.departureDateDisplay }}</span>
          </div>
          <div v-for="(segment, index) in selectedFlight.segments" :key="segment.id">
            <div class="flight-segment" v-if="(segment.segmentAller && selectedDate === 'departure')">
              <div class="segments-container">
                <div class="segment-first-column">
                  <div class="segment-first-row"><h3>{{ segment.departureTime }}</h3></div>
                  <div class="segment-second-row"><h4>{{ segment.flightDuration }}</h4></div>
                  <div class="segment-third-row"><h3>{{ segment.arrivalTime }}</h3></div>
                </div>
                <div class="segment-second-column">
                  <div class="segment-first-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                  <div class="segment-second-row"><img src="@/assets/img/voyage/plane-top.png" alt="plane"></div>
                  <div class="segment-third-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                </div>
                <div class="segment-third-column">
                  <div class="segment-first-row"><h3>{{ segment.departureCity}} ( {{ segment.departureAirportCode }} )</h3></div>
                  <div class="segment-second-row"><h4>{{ segment.airline }}</h4></div>
                  <div class="segment-third-row"><h3>{{ segment.arrivalCity}} ( {{ segment.arrivalAirportCode }} )</h3></div>
                </div>
              </div>
            </div>
            <div class="escale" v-if="segment.layoverDuration && checkStopoverIndex(index) && selectedDate === 'departure'">
              <img src="@/assets/img/voyage/clock.png" alt="clock">
              <p><strong>Escale :</strong> {{ segment.layoverDuration }}</p>
            </div>
          </div>

          <div class="flight-selected-date" v-if="(selectedDate === 'return')">
            <span class="flight-arrow-icon">⏷</span> <span class="flight-date-text">{{ selectedFlight.returnDateTakeOffDisplay }}</span>
          </div>
          <div v-for="(segment, index) in selectedFlight.segments" :key="segment.id">
            <div class="flight-segment" v-if="(!segment.segmentAller && selectedDate === 'return')">
              <div class="segments-container">
                <div class="segment-first-column">
                  <div class="segment-first-row"><h3>{{ segment.departureTime }}</h3></div>
                  <div class="segment-second-row"><h4>{{ segment.flightDuration }}</h4></div>
                  <div class="segment-third-row"><h3>{{ segment.arrivalTime }}</h3></div>
                </div>
                <div class="segment-second-column">
                  <div class="segment-first-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                  <div class="segment-second-row"><img src="@/assets/img/voyage/plane-top.png" alt="plane"></div>
                  <div class="segment-third-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                </div>
                <div class="segment-third-column">
                  <div class="segment-first-row"><h3>{{ segment.departureCity }} ( {{ segment.departureAirportCode }} )</h3></div>
                  <div class="segment-second-row"><h4>{{ segment.airline }}</h4></div>
                  <div class="segment-third-row"><h3>{{ segment.arrivalCity }} ( {{ segment.arrivalAirportCode }} )</h3></div>
                </div>
              </div>
            </div>
            <div class="escale" v-if="segment.layoverDuration && checkStopoverIndex(index) && selectedDate === 'return' && !segment.segmentAller">
              <img src="@/assets/img/voyage/clock.png" alt="clock">
              <p><strong>Escale :</strong> {{ segment.layoverDuration }}</p>
            </div>
          </div>

          <div class="flight-selected-date" v-if="(selectedDate === 'departure')">
            <img src="@/assets/img/voyage/location.png" alt="pin"> <span class="flight-date-text">{{ selectedFlight.departureDateLandingDisplay}}</span>
          </div>
          <div class="flight-selected-date" v-if="(selectedDate === 'return')">
            <img src="@/assets/img/voyage/location.png" alt="pin"> <span class="flight-date-text">{{ selectedFlight.returnDateDisplay }}</span>
          </div>
        </div>
      </div>
      <!-- FLIGHT - Fin PopUp -->


      <!-- FLIGHT - Mobile Specific Layout -->
      <div v-if="currentTab === 'flight'  && !noTransport" class="flight-card-wrapper-mobile">
        <div v-if="flights.length === 0" class="no-results">
          <p>Aucun vol trouvé par notre partenaire.</p>
          <p v-if="tripData.exactDates">Vous avez sélectionné des dates précises, des dates différentes pourraient donner des résultats.</p>
        </div>
        <div class="flight-card-mobile" v-for="(flight, index) in flights" :key="index" @click="openPopup(flight)">
          <div class="flight-card-left-mobile">
            <div class="rows-container">
              <div class="mobile-way-column">
                <h3 class="way-text">Aller</h3>
              </div>
              <div class="flight-info-mobile">
                <div class="flight-row">
                  <h3 class="from-to">{{ flight.from }}</h3>
                  <p>{{ flight.departure_airline_name }}</p>
                  <h3 class="from-to-right">{{ flight.to }}</h3>
                </div>
                <div class="line-container">
                  <span class="dot"></span>
                  <span class="line"></span>
                  <h4>{{ flight.departure_flight_duration }}</h4>
                  <span class="line"></span>
                  <span class="dot"></span>
                </div>
                <div class="time-row">
                  <div>
                    <h3 style="font-size: 0.8rem;">{{ flight.departureHour }}</h3>
                    <p>{{ flight.departureDateDisplay }}</p>
                  </div>
                  <p>{{ flight.correspondanceAller === 0 ? 'Direct' : flight.correspondanceAller + ' escale' + (flight.correspondanceAller > 1 ? 's' : '') }}</p>
                  <div>
                    <h3 style="text-align: right; font-size: 0.8rem;">{{ flight.departureHourLanding }}</h3>
                    <p>{{ flight.departureDateLandingDisplay }}</p>
                  </div>
                </div>
              </div>
              <div class="row-separator"></div>
              <div class="mobile-way-column">
                <h3 class="way-text" style="margin-top: -20px;">Retour</h3>
              </div>
              <div class="flight-info-mobile">
                <div class="flight-row">
                  <h3 class="from-to">{{ flight.to }}</h3>
                  <p>{{ flight.return_airline_name }}</p>
                  <h3 class="from-to-right">{{ flight.from }}</h3>
                </div>
                <div class="line-container">
                  <span class="dot"></span>
                  <span class="line"></span>
                  <h4>{{ flight.return_flight_duration }}</h4>
                  <span class="line"></span>
                  <span class="dot"></span>
                </div>
                <div class="time-row" style="margin-bottom:10px">
                  <div>
                    <h3 style="font-size: 0.8rem;">{{ flight.returnHourTakeOff }}</h3>
                    <p>{{ flight.returnDateTakeOffDisplay }}</p>
                  </div>
                  <p>{{ flight.correspondanceRetour === 0 ? 'Direct' : flight.correspondanceRetour + ' escale' + (flight.correspondanceRetour > 1 ? 's' : '') }}</p>
                  <div>
                    <h3 style="text-align: right; font-size: 0.8rem;">{{ flight.returnHour }}</h3>
                    <p>{{ flight.returnDateDisplay }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flight-card-right-mobile">
            <div class="text-container">
              <h2>{{ flight.price }}€</h2>
              <h4>par pers.</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- FLIGHT - FIN Mobile Specific Layout -->


      <!-- TRAIN - Desktop Layout -->
      <div v-if="currentTab === 'train'  && !noTransport" class="train-content-container">
        <div v-if="trains.outboundTrips.length === 0" class="no-results">
          <p>Aucun train ou combinaison de transports terrestres n'a été trouvé par notre partenaire.</p>
        </div>
        <div class="train-card-wrapper" v-if="trains.outboundTrips.length > 0">
          <div class="train-card" v-for="(train, index) in isSelectingReturn ? trains.returnTrips : trains.outboundTrips" :key="index" @click="selectTrain(train)">
            <div class="train-card-left">
              <div class="rows-container">
                <div class="train-info">
                  <div class="train-row">
                    <h3 class="from-to">{{ train.origine.city }}</h3>
                    <p>{{ train.segments[0].provider.company }}</p>
                    <h3 class="from-to-right">{{ train.destination.city }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ train.durationDisplay }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ train.departureHour }}</h3>
                      <p>{{ train.departureDateDisplay }}</p>
                    </div>
                    <p>{{ train.numberOfSegments - 1 === 0 ? 'Direct' : (train.numberOfSegments - 1) + ' correspondance' + ((train.numberOfSegments - 1) > 1 ? 's' : '') }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ train.arrivalHour }}</h3>
                      <p>{{ train.arrivalDateDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="train-card-right">
              <div class="text-container">
                <h2>{{ formatPrice(formatPricePerPerson(train.price)) }}€</h2>
                <h4>par pers.</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="side-column" style="display: block">
          <div class="selected-trip">
            <h3>Aller sélectionné</h3>
            <button v-if="selectedOutboundTrain.origine.name && isSelectingReturn" class="modify-button" @click="modifyOutbound">Modifier</button>
            <div class="trip-details">
              <div class="station">
                <p>{{ selectedOutboundTrain.origine.name }}</p>
                <p>{{ selectedOutboundTrain.departureHour }}</p>
              </div>
              <div class="line">
                <span class="vertical-line"></span>
              </div>
              <div class="station">
                <p>{{ selectedOutboundTrain.destination.name }}</p>
                <p>{{ selectedOutboundTrain.arrivalHour }}</p>
              </div>
            </div>
            <button @click="toggleSegments('outbound')" class="toggle-button">
              <img :src="showSegments.outbound ? require('@/assets/img/voyage/up-arrow.png') : require('@/assets/img/voyage/down-arrow.png')" alt="Toggle">
            </button>
            <div v-if="showSegments.outbound">
              <div v-for="segment in selectedOutboundTrain.segments" :key="segment.id" class="trip-details">
                <div class="station">
                  <p>{{ segment.origin.name }}</p>
                  <p>{{ formatTime(segment.departureLocalISO) }}</p>
                </div>
                <div class="segment-container">
                  <div class="lines">
                    <span class="vertical-line"></span>
                  </div>
                  <div class="segment-details">
                    <p>{{ segment.provider.company }}</p>
                  </div>
                </div>
                <div class="station">
                  <p>{{ segment.destination.name }}</p>
                  <p>{{ formatTime(segment.arrivalLocalISO) }}</p>
                </div>
              </div>
            </div>
            <div class="price">
              <h2>{{ formatPrice(formatPricePerPerson(selectedOutboundTrain.price)) }}€</h2>
              <p>par pers.</p>
            </div>
            <button v-if="!isSelectingReturn" @click="chooseOutboundTrain" class="choose-button">Choisir</button>

            <div v-if="isSelectingReturn">
              <h3 style="margin-top: 30px;">Retour sélectionné</h3>
              <div class="trip-details">
                <div class="station">
                  <p>{{ selectedReturnTrain.origine.name }}</p>
                  <p>{{ selectedReturnTrain.departureHour }}</p>
                </div>
                <div class="line">
                  <span class="vertical-line"></span>
                </div>
                <div class="station">
                  <p>{{ selectedReturnTrain.destination.name }}</p>
                  <p>{{ selectedReturnTrain.arrivalHour }}</p>
                </div>
              </div>
              <button @click="toggleSegments('return')" class="toggle-button">
                <img :src="showSegments.return ? require('@/assets/img/voyage/up-arrow.png') : require('@/assets/img/voyage/down-arrow.png')" alt="Toggle">
              </button>
              <div v-if="showSegments.return">
                <div v-for="segment in selectedReturnTrain.segments" :key="segment.id" class="trip-details">
                  <div class="station">
                    <p>{{ segment.origin.name }}</p>
                    <p>{{ formatTime(segment.departureLocalISO) }}</p>
                  </div>
                  <div class="segment-container">
                    <div class="lines">
                      <span class="vertical-line"></span>
                    </div>
                    <div class="segment-details">
                      <p>{{ segment.provider.company }}</p>
                    </div>
                  </div>
                  <div class="station">
                    <p>{{ segment.destination.name }}</p>
                    <p>{{ formatTime(segment.arrivalLocalISO) }}</p>
                  </div>
                </div>
              </div>
              <div class="price">
                <h2>{{ formatPrice(formatPricePerPerson(selectedReturnTrain.price)) }}€</h2>
                <p>par pers.</p>
              </div>
              
              <div class="lines">
                <span class="vertical-line"></span>
              </div>

              <div class="price">
                <p>Total :</p>
                <h2>{{ formatPrice(formatPricePerPerson(selectedReturnTrain.price)) + formatPrice(formatPricePerPerson(selectedOutboundTrain.price)) }}€</h2>
                <p>par pers.</p>
              </div>

              <button @click="validateSelection" class="validate-button">Valider</button>
            </div>
          </div>
        </div>



      </div>
      <!-- TRAIN - FIN Desktop Layout -->
    
      <!-- TRAIN - Mobile Specific Layout -->
      <div v-if="currentTab === 'train'  && !noTransport" class="train-mobile-layout">
        <div v-if="trains.outboundTrips.length === 0" class="no-results">
          <p>Aucun train ou combinaison de transports terrestres n'a été trouvé par notre partenaire.</p>
        </div>
        <div class="train-card-wrapper">
          <div class="train-card" v-for="(train, index) in isSelectingReturn ? trains.returnTrips : trains.outboundTrips" :key="index" @click="selectTrain(train)">
            <div class="train-card-left">
              <div class="rows-container">
                <div class="train-info">
                  <div class="train-row">
                    <h3 class="from-to">{{ train.origine.city }}</h3>
                    <p>{{ train.segments[0].provider.company }}</p>
                    <h3 class="from-to-right">{{ train.destination.city }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ train.durationDisplay }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ train.departureHour }}</h3>
                      <p>{{ train.departureDateDisplay }}</p>
                    </div>
                    <p>{{ train.numberOfSegments - 1 === 0 ? 'Direct' : (train.numberOfSegments - 1) + ' correspondance' + ((train.numberOfSegments - 1) > 1 ? 's' : '') }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ train.arrivalHour }}</h3>
                      <p>{{ train.arrivalDateDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="train-card-right">
              <div class="text-container">
                <h2>{{ formatPrice(formatPricePerPerson(train.price)) }}€</h2>
                <h4>par pers.</h4>
              </div>
            </div>
          </div>
        </div>

        <transition name="slide-up">
          <div v-if="sideColumnVisible" class="mobile-side-column">
            <div class="selected-trip">
              <h3>Aller sélectionné</h3>
              <button v-if="selectedOutboundTrain.origine.name && isSelectingReturn" class="modify-button" @click="modifyOutbound">Modifier</button>
              <div class="trip-details">
                <div class="station">
                  <p>{{ selectedOutboundTrain.origine.name }}</p>
                  <p>{{ selectedOutboundTrain.departureHour }}</p>
                </div>
                <div class="line">
                  <span class="vertical-line"></span>
                </div>
                <div class="station">
                  <p>{{ selectedOutboundTrain.destination.name }}</p>
                  <p>{{ selectedOutboundTrain.arrivalHour }}</p>
                </div>
                <button @click="toggleSegments('outbound')" class="toggle-button">
                  <img :src="showSegments.outbound ? require('@/assets/img/voyage/up-arrow.png') : require('@/assets/img/voyage/down-arrow.png')" alt="Toggle">
                </button>
              </div>
              
              <div v-if="showSegments.outbound">
                <div v-for="segment in selectedOutboundTrain.segments" :key="segment.id" class="trip-details">
                  <div class="station">
                    <p>{{ segment.origin.name }}</p>
                    <p>{{ formatTime(segment.departureLocalISO) }}</p>
                  </div>
                  <div class="segment-container">
                    <div class="lines">
                      <span class="vertical-line"></span>
                    </div>
                    <div class="segment-details">
                      <p>{{ segment.provider.company }}</p>
                    </div>
                  </div>
                  <div class="station">
                    <p>{{ segment.destination.name }}</p>
                    <p>{{ formatTime(segment.arrivalLocalISO) }}</p>
                  </div>
                </div>
              </div>
              <div class="price">
                <h2>{{ formatPrice(formatPricePerPerson(selectedOutboundTrain.price)) }}€</h2>
                <p>par pers.</p>
              </div>
              <button v-if="!isSelectingReturn" @click="chooseOutboundTrain" class="choose-button">Choisir</button>

              <div v-if="isSelectingReturn">
                <h3 style="margin-top: 30px;">Retour sélectionné</h3>
                <div class="trip-details">
                  <div class="station">
                    <p>{{ selectedReturnTrain.origine.name }}</p>
                    <p>{{ selectedReturnTrain.departureHour }}</p>
                  </div>
                  <div class="line">
                    <span class="vertical-line"></span>
                  </div>
                  <div class="station">
                    <p>{{ selectedReturnTrain.destination.name }}</p>
                    <p>{{ selectedReturnTrain.arrivalHour }}</p>
                  </div>
                  <button @click="toggleSegments('return')" class="toggle-button">
                    <img :src="showSegments.return ? require('@/assets/img/voyage/up-arrow.png') : require('@/assets/img/voyage/down-arrow.png')" alt="Toggle">
                  </button>
                </div>
                
                <div v-if="showSegments.return">
                  <div v-for="segment in selectedReturnTrain.segments" :key="segment.id" class="trip-details">
                    <div class="station">
                      <p>{{ segment.origin.name }}</p>
                      <p>{{ formatTime(segment.departureLocalISO) }}</p>
                    </div>
                      <div class="segment-container">
                        <div class="lines">
                          <span class="vertical-line"></span>
                        </div>
                        <div class="segment-details">
                          <p>{{ segment.provider.company }}</p>
                        </div>
                      </div>
                      <div class="station">
                        <p>{{ segment.destination.name }}</p>
                        <p>{{ formatTime(segment.arrivalLocalISO) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="price">
                    <h2>{{ formatPrice(formatPricePerPerson(selectedReturnTrain.price)) }}€</h2>
                    <p>par pers.</p>
                  </div>
                  <button @click="validateSelection" class="validate-button">Valider</button>
                </div>
            </div>
          </div>
        </transition>

          <button class="toggle-mobile-side-column-button" @click="toggleSideColumn">
            <span :class="{'rotate-up': !sideColumnVisible, 'rotate-down': sideColumnVisible}">></span>
          </button>

      </div>
      <!-- TRAIN - FIN Mobile Specific Layout -->

      <!-- Popup "Chargement des hotels" -->
      <div v-if="hotelLoadingPopup" class="hotel-loading-popup">
        <div class="hotel-loading-content">
          <p>Chargement des hôtels...</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { saveFlightData, saveGroundTransportationsData } from "../functions/storeTransportations";
import { doc, getDoc, runTransaction, updateDoc } from "firebase/firestore";
import { db } from "../firebase.js";
import { saveHotelData } from "../functions/storeHotels";
import { Timestamp } from 'firebase/firestore';
import { availableTripDays } from "../functions/availableDays";


export default {
  name: "TransportationsMain",
  props: ["docRef", "exactDates", "isModifying", "fromProfile"],
  data() {
    return {
      loading: true,
      hotelLoading: false,
      hotelLoadingPopup: false,
      flights: [],
      trains: {
        outboundTrips: [],
        returnTrips: [],
        bundles: [],
      },
      selectedTrain: {
        origine: { name: '' },
        destination: { name: '' },
        departureHour: '',
        arrivalHour: '',
        price: ''
      },
      selectedOutboundTrain: {
        origine: { name: '' },
        destination: { name: '' },
        departureHour: '',
        arrivalHour: '',
        price: ''
      },
      selectedReturnTrain: {
        origine: { name: '' },
        destination: { name: '' },
        departureHour: '',
        arrivalHour: '',
        price: ''
      },
      isSelectingReturn: false,
      showSegments: {
        outbound: false,
        return: false,
      },
      hotels: [],
      departureDate: '',
      returnDate: '',
      showPopup: false,
      selectedFlight: {},
      selectedDate: 'departure',
      localIsModifying: false,
      noTransport: false,
      currentTab: 'train',
      tripData: {},
      showOutboundTrips: false,
      sideColumnVisible: false,
      mobileDepartureDate: '',
      mobileReturnDate: '',
      isDatePickerOpen: false,
    };
  },
  async mounted() {
    console.log("DocRef :", this.docRef);
    const localDocRef = this.docRef;
    if (localDocRef) {
      await this.transportationsLoad(localDocRef);
      await this.loadTripDatas(this.docRef);
      if (!this.fromProfile) {
        await this.hotelsLoad(this.docRef);
      }
    } else {
      console.error("docRef is missing");
    }
    this.selectFirstTrips();
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.mobile-side-column');
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    toggleDatePicker() {
      this.isDatePickerOpen = !this.isDatePickerOpen;
    },
    async loadTripDatas(docRef) {
      try {
        const tripDataRef = doc(db, "tripdatas", docRef);
        const tripDataSnapshot = await getDoc(tripDataRef);
        if (tripDataSnapshot.exists()) {
          const data = tripDataSnapshot.data();
          this.tripData = data;
        } else {
          console.log("Problème de chargement des données");
        }
      } catch (error) {
        console.error("Problème lors du chargement des données : ", error);
      }
    },
    selectFirstTrips() {
      if (this.trains.outboundTrips.length > 0) {
        this.selectedOutboundTrain = this.trains.outboundTrips[0];
      }
      if (this.trains.returnTrips.length > 0) {
        this.selectedReturnTrain = this.trains.returnTrips[0];
      }
    },
    formatPrice(price) {
      return Math.round(price * 10) / 10;
    },
    modifyOutbound() {
      this.showOutboundTrips = true;
      this.isSelectingReturn = false;
    },
    selectTrain(train) {
      if (this.isSelectingReturn) {
        this.selectedReturnTrain = train;
        if (this.isMobile) {
          this.sideColumnVisible = true;
          this.scrollToBottom();
        }
      } else {
        this.selectedOutboundTrain = train;
        this.showOutboundTrips = false;
        if (this.isMobile) {
        this.sideColumnVisible = true;
        }
      }  
    },  
    toggleSegments(direction) {
      this.showSegments[direction] = !this.showSegments[direction];
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    chooseOutboundTrain() {
      this.isSelectingReturn = true;
      this.selectedReturnTrain = this.trains.returnTrips.length > 0 ? this.trains.returnTrips[0] : {};
    },
    getSelectedBundle() {
      for (const bundle of this.trains.bundles) {
        const tripIds = bundle.trips;
        const outboundTripId = this.selectedOutboundTrain.id;
        const returnTripId = this.selectedReturnTrain.id;

        if (tripIds.includes(outboundTripId) && tripIds.includes(returnTripId)) {
          return bundle;
        }
      }
      throw new Error("Aucun bundle correspondant trouvé pour les trajets sélectionnés.");
    },
    async validateSelection() {
      try {
          if (this.hotelLoading) {
              this.hotelLoadingPopup = true;
              await this.waitForHotelLoading();
          }

          const selectedBundle = this.getSelectedBundle();
          const selectedGroundTransportation = {
              outboundTrip: this.selectedOutboundTrain,
              returnTrip: this.selectedReturnTrain,
              bundle: selectedBundle,
          };

          const tripDataRef = doc(db, "tripdatas", this.docRef);
          await runTransaction(db, async (transaction) => {
              const tripDataSnapshot = await transaction.get(tripDataRef);
              if (!tripDataSnapshot.exists()) {
                  throw new Error(`Document tripdatas avec ID ${this.docRef} n'existe pas.`);
              }

              const tripData = tripDataSnapshot.data();
              if (tripData.selectedFlight) {
                  transaction.update(tripDataRef, {
                      selectedFlight: null,
                  });
              }

              const arrivalTimestamp = new Date(this.selectedOutboundTrain.arrivalDate);
              const returnDepartureTimestamp = new Date(this.selectedReturnTrain.departureDate);

              // Calculer la durée en millisecondes entre l'arrivée et le départ
              const nightsInMilliseconds = returnDepartureTimestamp - arrivalTimestamp;

              // Convertir cette durée en jours
              const nightsInDest = Math.floor(nightsInMilliseconds / (1000 * 60 * 60 * 24));

              // Calculer les autres timestamps
              const departureTimestamp = new Date(this.selectedOutboundTrain.departureDate);
              const returnTimestamp = new Date(this.selectedReturnTrain.arrivalDate);

              const departureTimestampStore = Timestamp.fromDate(departureTimestamp);
              const returnTimestampStore = Timestamp.fromDate(returnTimestamp);

              // Calculer la durée en jours entre departureDate et returnDate
              const durationInMilliseconds = returnTimestamp - departureTimestamp;
              const durationInDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24)) + 1; // Conversion de millisecondes en jours

              console.log("duration: ", durationInDays);
              console.log("nightsInDest: ", nightsInDest);

              // Mise à jour du document tripdatas avec les nouvelles informations
              transaction.update(tripDataRef, {
                  selectedGroundTransportation: selectedGroundTransportation,
                  departureDate: departureTimestampStore,
                  returnDate: returnTimestampStore,
                  duration: durationInDays,
                  nightsInDest: nightsInDest
              });
          });

          // Après la transaction, récupérez à nouveau les données mises à jour
          const updatedTripDataSnapshot = await getDoc(tripDataRef);
          const updatedTripData = updatedTripDataSnapshot.data();

          // Appeler la fonction availableTripDays avec les données mises à jour
          const availableDays = availableTripDays(updatedTripData);

          // Mettre à jour le document avec le champ availableDays
          await updateDoc(tripDataRef, {
              availableDays: availableDays
          });

          console.log("Transport terrestre sélectionné mis à jour avec succès.");

          if (this.fromProfile) {
              // Redirection vers la page SumUpPage
              this.$router.push({ name: 'SumUpPage', params: { tripId: this.docRef } });
          } else {
              // Émission de l'événement updateStep avec la valeur "selectHotels"
              this.$emit('updateStep', 'selectHotels');
          }
      } catch (error) {
          console.error("Erreur lors de la mise à jour du transport terrestre sélectionné:", error);
      }
    },

    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    async validateDates() {
      this.localIsModifying = true;
      console.log("Validating dates:", this.departureDate, this.returnDate);
      this.selectedOutboundTrain = {
        origine: { name: '' }, 
        destination: { name: '' },
        departureHour: '',
        arrivalHour: '',
        price: ''
      },
      this.selectedReturnTrain = {
        origine: { name: '' },
        destination: { name: '' },
        departureHour: '',
        arrivalHour: '',
        price: ''
      },  
      this.isSelectingReturn = false;
      if (this.departureDate && this.returnDate) {
        const formattedDepartureDate = this.formatDate(this.departureDate);
        const formattedReturnDate = this.formatDate(this.returnDate);
        console.log("Dates are valid, calling transportationsLoad with:", formattedDepartureDate, formattedReturnDate);
        await this.transportationsLoad(this.docRef, formattedDepartureDate, formattedReturnDate);
      } else {
        console.log("One or both dates are missing, not calling transportationsLoad");
      }
    },
    async validateMobileDates() {
      this.localIsModifying = true;
      console.log("Validating mobile dates:", this.mobileDepartureDate, this.mobileReturnDate);
      if (this.mobileDepartureDate && this.mobileReturnDate) {
        const formattedDepartureDate = this.formatDate(this.mobileDepartureDate);
        const formattedReturnDate = this.formatDate(this.mobileReturnDate);
        console.log("Dates are valid, calling transportationsLoad with:", formattedDepartureDate, formattedReturnDate);
        await this.transportationsLoad(this.docRef, formattedDepartureDate, formattedReturnDate);
      } else {
        console.log("One or both dates are missing, not calling transportationsLoad");
      }
    },
    async transportationsLoad(docRef, departureDate, returnDate) {
      this.loading = true;
      console.log("Starting transportationsLoad with docRef:", docRef, "departureDate:", departureDate, "returnDate:", returnDate);
      try {
        const requestBody = { docRef: docRef };
        if (departureDate && returnDate) {
          requestBody.departureDate = departureDate;
          requestBody.returnDate = returnDate;
        }

        console.log("Request body:", requestBody);

        const response = await fetch(
          "https://europe-west1-alegotravel.cloudfunctions.net/api/getTransportations",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur réseau ou côté serveur");
        }

        const data = await response.json();
        console.log("Response data:", data);

        // Vérifie que data.flightsTransportationsResult est un tableau avant de le filtrer
        const filteredFlights = Array.isArray(data.flightsTransportationsResult) 
          ? this.filterUniqueFlights(data.flightsTransportationsResult) 
          : [];

        // Vérifie que groundTransportations est bien un objet et initialise ses trips en tant que tableau vide s'il n'existe pas
        let groundTransportations = data.trainsTransportationsResult || {};
        groundTransportations.trips = Array.isArray(groundTransportations.trips) 
          ? groundTransportations.trips 
          : [];

        console.log("Trains :", groundTransportations.trips);

        // Sépare les trajets outbound et return s'il y a des trips
        this.trains.outboundTrips = groundTransportations.trips.filter(trip => trip.direction === 'outboundTrip');
        this.trains.returnTrips = groundTransportations.trips.filter(trip => trip.direction === 'returnTrip');
        this.trains.bundles = Array.isArray(groundTransportations.bundles) 
          ? groundTransportations.bundles 
          : [];

        this.flights = filteredFlights;

        // Définir `this.currentTab` en fonction de la disponibilité des trains
        if (this.trains.outboundTrips.length > 0 || this.trains.returnTrips.length > 0 || this.trains.bundles.length > 0) {
          this.currentTab = 'train';
        } else {
          this.currentTab = 'flight';
        }

        // Sauvegarde des données des vols et des transports terrestres
        await Promise.all(filteredFlights.map(flight => saveFlightData(flight, docRef)));
        await saveGroundTransportationsData(groundTransportations, docRef);
        console.log("Transportations loaded successfully:", filteredFlights);
      } catch (error) {
        console.error("Erreur lors de l'appel à la fonction Firebase:", error);
      } finally {
        this.loading = false;
      }
    },

    filterUniqueFlights(flights) {
      const flightMap = new Map();
      flights.forEach((flight) => {
        const key = `${flight.departure_airline_name}-${flight.departureHour}-${flight.departureHourLanding}`;
        if (!flightMap.has(key) || flightMap.get(key).price > flight.price) {
          flightMap.set(key, flight);
        }
      });
      return Array.from(flightMap.values());
    },
    async hotelsLoad(docRef) {
      this.hotelLoading = true;
      try {
        const tripDataRef = doc(db, "tripdatas", docRef);
        const docSnapshot = await getDoc(tripDataRef);

        if (!docSnapshot.exists()) {
          throw new Error(`Aucun document trouvé pour docRef: ${docRef}`);
        }

        const tripData = docSnapshot.data();
        console.log(tripData.departureDate, tripData.returnDate);

        let checkin, checkout;
        if (tripData.exactDates) {
          if (tripData.departureDate && tripData.returnDate) {
            const departureDate = tripData.departureDate.toDate();
            const returnDate = tripData.returnDate.toDate();

            checkin = this.formatDate(departureDate);
            checkout = this.formatDate(returnDate);
          } else {
            throw new Error("Les dates de départ et de retour ne sont pas définies.");
          }
        } else {
          if (tripData.departureDate) {
            const startDate = tripData.departureDate.toDate();
            checkin = this.formatDate(startDate);

            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + tripData.duration);
            checkout = this.formatDate(endDate);
          } else {
            throw new Error("La date de début de période n'est pas définie.");
          }
        }

        const requestBody = {
          adults: tripData.nbPerson,
          checkin: checkin,
          checkout: checkout,
          longitude: parseFloat(tripData.longitudeTo),
          latitude: parseFloat(tripData.latitudeTo),
        };

        console.log("Request Body :", requestBody);

        const response = await fetch(
          "https://europe-west1-alegotravel.cloudfunctions.net/api/hotelAPI",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur réseau ou côté serveur");
        }

        const data = await response.json();
        console.log("Datas hotels : ", data);
        this.hotels = data.detailedHotels;

        await Promise.all(this.hotels.map(hotel => saveHotelData(hotel, docRef)));

      } catch (error) {
        console.error("Erreur lors de l'appel à l'API des hôtels:", error);
      } finally {
        this.hotelLoading = false;
        if (this.hotelLoadingPopup) {
          this.hotelLoadingPopup = false;
          this.$emit('updateStep', 'selectHotels');
        }
      }
    },
    waitForHotelLoading() {
      return new Promise((resolve) => {
        const checkInterval = setInterval(() => {
          if (!this.hotelLoading) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100);
      });
    },
    closePopup() {
      this.showPopup = false;
    },
    openPopup(trip) {
      this.selectedFlight = trip;
      this.showPopup = true;
    },
    selectDate(dateType) {
      if (dateType === 'departure') {
        this.selectedDate = 'departure';
      } else if (dateType === 'return') {
        this.selectedDate = 'return';
      }
    },
    checkStopoverIndex(index) {
      if (this.selectedDate === 'departure') {
        return index < this.selectedFlight.correspondanceAller;
      } else if (this.selectedDate === 'return') {
        return index < this.selectedFlight.correspondanceRetour + this.selectedFlight.correspondanceAller + 1;
      }
      return false;
    },
    async chooseFlight() {
      if (this.fromProfile) {
        this.finalizeFlightChoice();
      } else {
        if (this.hotelLoading) {
          this.hotelLoadingPopup = true;
          await this.waitForHotelLoading();
          this.finalizeFlightChoice();
        } else {
          this.finalizeFlightChoice();
        }
      }
    },
    async finalizeFlightChoice() {
      if (!this.fromProfile) {
        this.hotelLoading = true;
      }

      const tripDataRef = doc(db, 'tripdatas', this.docRef);

      try {
        await runTransaction(db, async (transaction) => {
          const tripDataSnapshot = await transaction.get(tripDataRef);
          if (!tripDataSnapshot.exists()) {
            throw new Error(`Document tripdatas avec ID ${this.docRef} n'existe pas.`);
          }

          const tripData = tripDataSnapshot.data();
          if (tripData.selectedGroundTransportation) {
            transaction.update(tripDataRef, {
              selectedGroundTransportation: null,
            });
          }

          const departureTimestamp = new Date(this.selectedFlight.departureDate);
          const returnTimestamp = new Date(this.selectedFlight.returnDate);

          const departureTimestampStore = Timestamp.fromDate(departureTimestamp);
          const returnTimestampStore = Timestamp.fromDate(returnTimestamp);

          // Calculer la durée en jours entre departureDate et returnDate
          const durationInMilliseconds = returnTimestamp - departureTimestamp;
          const durationInDays = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24)) + 1; // Conversion de millisecondes en jours

          // Calculer nightsInDest entre returnDateTakeOff et departureDateLanding
          const returnDateTakeOff = new Date(this.selectedFlight.returnDateTakeOff);
          const departureDateLanding = new Date(this.selectedFlight.departureDateLanding);

          const nightsInMilliseconds = returnDateTakeOff - departureDateLanding;
          const nightsInDest = Math.floor(nightsInMilliseconds / (1000 * 60 * 60 * 24)); // Conversion de millisecondes en jours

          // Mise à jour du document tripdatas avec les nouvelles informations
          transaction.update(tripDataRef, {
            selectedFlight: this.selectedFlight,
            exactDates: tripData.exactDates,
            departureDate: departureTimestampStore,
            returnDate: returnTimestampStore,
            duration: durationInDays, // Mettre à jour la durée
            nightsInDest: nightsInDest,
          });

        });

        // Après la transaction, récupérez à nouveau les données mises à jour
        const updatedTripDataSnapshot = await getDoc(tripDataRef);
        const updatedTripData = updatedTripDataSnapshot.data();

        // Appeler la fonction availableTripDays avec les données mises à jour
        const availableDays = availableTripDays(updatedTripData);

        // Mettre à jour le document avec le champ availableDays
        await updateDoc(tripDataRef, {
            availableDays: availableDays
        });
        console.log('Vol choisi avec succès.');

        if (this.fromProfile && updatedTripData.selectedHotel) {
          // Redirection vers la page SumUpPage
          this.$router.push({ name: 'SumUpPage', params: { tripId: this.docRef } });
        } else {
          // Émission de l'événement updateStep avec la valeur "selectHotels"
          this.$emit('updateStep', 'selectHotels');
        }

      } catch (error) {
        console.error('Erreur lors du choix du vol:', error);
        if (!this.fromProfile) {
          this.hotelLoading = false;
        }
      }
    },
    handleNoTransportChange() {
      if (this.noTransport) {
        this.hotelLoading = true;
        this.hotelsLoad(this.docRef).then(() => {
          this.hotelLoading = false;
        });
      }
    },
    navigateToHotels() {
      if (this.hotelLoading) {
        this.hotelLoadingPopup = true;
        this.waitForHotelLoading().then(() => {
          this.hotelLoadingPopup = false;
          this.$emit('updateStep', 'selectHotels');
        });
      } else {
        this.$emit('updateStep', 'selectHotels');
      }
    },
    formatPricePerPerson(price) {
      return (price / this.tripData.nbPerson).toFixed(2);
    },
    toggleSideColumn() {
      this.sideColumnVisible = !this.sideColumnVisible;
    }
  },
};
</script>



<style scoped>

.transportation-container {
  width: 100%;
  margin: auto;
}

.flight-card-wrapper-mobile {
  display: none;
}

.flight-card-wrapper, .train-card-wrapper {
  padding-bottom: 60px;
}

.train-card-wrapper {
  display: flex;
  width: 90%;
  justify-content: left;
  flex-direction: column;
}

.side-column {
  width: 30%;
  margin-top: 15px;
  position: -webkit-sticky; /* Pour support Safari */
  position: sticky;
  top: 30px;
  height: auto;
  max-height: 90vh; /* Ensure side column has enough height */
  overflow: auto;
}

.selected-trip {
  border: 1px solid var(--gray);
  border-radius: 21px;
  padding: 20px;
  background-color: var(--white);
  text-align: center;
}

.trip-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.station {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px; /* Espacement réduit entre les segments */
  text-align: left;
  font-size: 0.85rem;
}

.trip-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alignement des segments à gauche */
  text-align: left;
  border-bottom: 1px dashed var(--gray);
  padding-bottom: 5px; /* Espacement en bas de chaque segment */
  margin-bottom: 5px; /* Espacement en bas de chaque segment */
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price h2 {
  color: #064748;
  font-size: 1.3rem;
  margin: 0;
  margin-top: 10px;
}

.price p {
  color: #064748;
  font-size: 1rem;
  margin: 0;
}

.selected-trip h3 {
  color: var(--jaune);
  font-size: 1.1rem;
  margin-bottom: 10px;
  margin-top: 0;
}

.toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}

.toggle-button img {
  width: 20px;
  height: 20px;
}

.toggle-button:hover {
  background-color: var(--jaune);
}

.lines {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Espacement réduit entre les segments */
}

.lines .vertical-line {
  width: 2px; /* Ajuster la largeur de la ligne */
  height: 50px; /* Ajuster la hauteur de la ligne */
  background-color: var(--black);
  margin: 0 10px; /* Espace autour de la ligne */
}

.segment-container {
  display: grid;
  grid-template-columns: auto 1fr; /* Deux colonnes */
  align-items: center;
  margin-bottom: 5px;
}

.segment-details {
  margin-left: 10px;
  font-size: 0.8rem; /* Taille de la police réduite */
  color: #424242; /* Couleur gris foncé */
}

.modify-button {
  color: var(--vert);
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end; /* Aligner à droite */
}
 
 .validate-button {
  background-color: var(--vert);
  color: var(--white);
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  margin-top: 20px;
}

.validate-button:hover {
  background-color: var(--jaune);
}

.dates-and-choice {
  max-width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto;
}

.dates-picker {
  align-items: flex-start;
  width: 70%;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  justify-self: start;
  gap: 10px;
}

.calendars {
  display: flex;
  justify-content: space-between;
}

.loading-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 82vh;
  border: 1px solid var(--lighgrey);
  background-color: var(--white);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.loading-content2 {
  text-align: center;
}

.departure-arrival p {
  color: var(--vert);
}
.departure-arrival img {
  height: 20px !important;
  width: 27px !important;
}

/* Bloc Transportations */

.train-or-flight {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
}

.train-or-flight-wrapper::before {
  content: '';
  position: absolute;
  top: 7%; /* Adjusted for padding */
  bottom: 7%; /* Adjusted for padding */
  left: 50%;
  width: 1px;
  background: gray;
  z-index: 1;
}

.train-or-flight .choice-bloc {
  color: #424242;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid gray;
  flex: 1;
  margin: 0; /* Supprime les marges pour coller les bordures */
  position: relative;
  z-index: 2;
  padding: 0 50px; /* Add padding */
  justify-content: space-around;
  cursor: pointer;
}

.train-or-flight .left {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.train-or-flight .highlighted {
  background-color: #e0e4e5;
}
.train-or-flight .right {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.train-or-flight .choice-bloc.highlighted {
  background-color: #e0e4e5;
  border: 2px solid var(--vert);
  color: var(--vert);
}

.train-or-flight .choice-bloc:not(.highlighted) {
  background-color: #f9f9f9;
  border: 1px solid gray;
}

.train-or-flight .choice-bloc img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.train-or-flight .choice-bloc.highlighted p {
  color: var(--vert) !important;
}

.dates-picker h2 {
  color: var(--vert);
  font-size: 1.2rem;
  font-weight: 700;
  border-bottom-width: 150px;
}

.trip-first-infos {
  display: flex;
  margin: auto;
  justify-content: space-between;
  flex-direction: row;
  align-items: center; 
  width: 90%; 
}

.departure-arrival {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 0px; 
  align-items: center;
}

.departure-arrival img {
  width: 60px;
  height: 50px;
  margin: 0 40px;
}

.departure-arrival p {
  color: #525252;
  font-weight: 900;
  font-size: 1.1rem;
}

.transportation-boolean {
  display: flex;
  align-items: center;
}

.transportation-boolean input[type="checkbox"] {
  margin-right: 10px; /* Espace entre la case à cocher et le label */
}

.transportation-boolean label {
  font-size: 1rem; /* Taille de la police du texte */
  color: #424242; /* Couleur du texte */
}

/* General Styles */

.flight-card {
  border: 1px solid var(--gray);
  border-radius: 21px;
  width: 90%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  background-color: var(--white);
  transition: box-shadow 0.3s ease-in-out; /* For hover effect */
  cursor: pointer; /* Pointer cursor on hover */
}

.flight-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Hover effect */
}

.flight-card-left {
  padding: 20px;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  background-color: var(--white);
}

.flight-card-right {
  background-color: var(--vert);
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flight-card-right .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flight-card-right h2,
.flight-card-right h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  color: var(--jaune);
}

.flight-card-right h2 {
  margin-bottom: 5px;
  font-size: 2.4rem;
}

.flight-card-right h4 {
  font-weight: normal;
  font-size: 1.3rem;
}

.rows-container {
  display: grid;
  row-gap: 0px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
}

.row-separator {
  border-bottom: 1px dashed var (--gray);
  margin: 10px 0;
}

.way-column {
  text-align: center;
}

.way-column h3 {
  color: var(--jaune);
}

.flight-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flight-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* Increased margin-bottom */
}

.flight-row .from-to {
  width: 35%;
}

.flight-row .from-to-right {
  width: 35%;
  text-align: right;
}

.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Increased margin-bottom */
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--black);
  border-radius: 50%;
  margin: 0;
}

.line {
  flex-grow: 1;
  height: 2px; /* Increased thickness */
  background-color: var(--black);
  margin: 0;
}

.line:first-child {
  margin-left: 0; /* Removed margin */
}

.line:last-child {
  margin-right: 0; /* Removed margin */
}

.time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flight-row h3,
.time-row h3 {
  color: var(--black);
  font-weight: bold;
  margin: 0;
}

.flight-row p,
.line-container h4,
.time-row p {
  margin: 0;
  font-size: 0.8rem;
  color: var (--black);
  text-align: center;
}

.flight-row p,
.time-row p {
  flex: 1;
  text-align: center;
}

.line-container h4 {
  font-weight: bold;
  margin: 0 20px;
}

/* Train Card Styles */

.train-card {
  border: 1px solid var(--gray);
  border-radius: 21px;
  width: 90%;
  margin: 20px 0;
  display: grid;
  grid-template-columns: 4fr 1fr;
  background-color: var(--white);
  transition: box-shadow 0.3s ease-in-out; /* For hover effect */
  cursor: pointer; /* Pointer cursor on hover */
}

.train-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Hover effect */
}

.train-card-left {
  padding: 40px;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  background-color: var(--white);
}

.train-card-right {
  background-color: var(--vert);
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.train-card-right .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.train-card-right h2,
.train-card-right h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #E9B949;
}

.train-card-right h2 {
  margin-bottom: 5px;
  font-size: 1.3rem !important;
}

.train-card-right h4 {
  font-weight: normal;
  font-size: 1.3rem;
}

.train-rows-container {
  display: grid;
  row-gap: 20px;
}

.train-row {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.train-row-separator {
  border-bottom: 1px dashed var (--gray);
  margin: 10px 0;
}

.train-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.train-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* Increased margin-bottom */
}

.train-row .from-to {
  width: 20%;
}

.train-row .from-to-right {
  width: 20%;
  text-align: right;
}

.train-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Increased margin-bottom */
}

.train-dot {
  width: 8px;
  height: 8px;
  background-color: var(--black);
  border-radius: 50%;
  margin: 0;
}

.train-line {
  flex-grow: 1;
  height: 2px; /* Increased thickness */
  background-color: var(--black);
  margin: 0;
}

.train-line:first-child {
  margin-left: 0; /* Removed margin */
}

.train-line:last-child {
  margin-right: 0; /* Removed margin */
}

.train-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.train-row h3,
.train-time-row h3 {
  color: var(--black);
  font-weight: bold;
  margin: 0;
}

.train-row p,
.train-line-container h4,
train-time-row p {
  margin: 0;
  font-size: 0.9rem;
  color: var (--black);
  text-align: center;
}

.train-row p,
train-time-row p {
  flex: 1;
  text-align: center;
}

train-line-container h4 {
  font-weight: bold;
  margin: 0 20px;
}

.flight-info h3, .train-info h3 {
  font-size: 0.8rem;
}
/* Popup styles */
.flight-popup {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Increased z-index */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.flight-popup-content {
  background-color: var(--white);
  margin: 5% auto; /* Centered */
  padding: 20px;
  border: 1px solid var (--gray);
  width: 50%; /* Adjust as needed */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s; /* Animation for pop-up */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flight-close {
  color: var(--black);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.flight-close:hover,
.flight-close:focus {
  color: var(--gray);
  text-decoration: none;
  cursor: pointer;
}

.flight-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.flight-popup-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.flight-popup-header .flight-arrow {
  margin: 0 5px;
}

.flight-popup-header .button-choose {
  padding: 10px 20px;
  background-color: var(--jaune);
  cursor: pointer;
  border-radius: 20px;
  margin-right: 30px;
  color: var(--white)
}

.flight-dates {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.flight-date {
  margin: 0 10px;
  padding: 5px 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: border-bottom 0.3s;
  color: var(--light-gray); /* Default color */
  font-weight: normal;
}

.flight-date.flight-selected {
  border-bottom: 2px solid var(--black);
  color: var(--black);
  font-weight: bold;
}

.flight-date:hover {
  border-bottom: 2px solid var(--gray);
}

.flight-selected-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--jaune);
  margin-top: 10px;
}

.flight-arrow-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.flight-date-text {
  font-size: 1.1rem;
  font-weight: 600;
}

.flight-segment {
  background-color: #dde3e4;
  padding: 10px;
  border-radius: 10px;
  margin: 10px auto;
  width: 80%; /* Adjust width to 80% */
}

.segments-container {
  width: 90%;
  display: flex;
  margin: auto;
}

.segment-first-column {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.segment-second-column {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.segment-third-column {
  flex: 0 0 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.segment-first-column h4, .segment-third-column h4 {
  font-size: 0.7rem;
  font-weight: 500;
  font-style: italic;
  color: var(--dark-gray);
}

.segment-first-column h3, .segment-third-column h3 {
  font-size: 0.9rem;
  color: var(--black);
}

.segment-second-column .segment-first-row img, .segment-second-column .segment-third-row img {
  width: 40px;
  height: 70px;
  margin: auto 5px;
}

.segment-second-column .segment-second-row img {
  transform: rotate(180deg);
  height: 30px;
  margin: auto 15px;
}

.escale {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin: auto;
  align-items: center;
  font-size: 0.8rem;
  gap: 15px;
}

.escale img {
  width: 30px;
  height: 30px;
  margin-left: 13px;
}

.flight-selected-date img {
  width: 25px;
  height: auto;
  margin: 10px 10px;
}

/* Styles for the hotel loading popup */
.hotel-loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hotel-loading-content {
  background: white;
  padding: 80px 130px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 1.2rem;
  color: var(--jaune);
}

.no-transport-container {
  height: 300px;
  display: flex;
  justify-content: center;
}

.navigate-hotels-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: var(--vert);
  color: var (--white);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 250px;
  font-weight: 600;
  margin: 40px auto;
  height: 100px;
}

.navigate-hotels-button:hover {
  background-color: var(--jaune);
}

/* Fin Bloc Transportations */

.train-mobile-layout {
  display: none;
}

.train-content-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 30px auto;
  width: 90%;
}

.dates-picker-mobile {
  display: none;
}

.no-results {
  margin: auto;
  width: 80%;
  text-align: center;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .transportation-container {
    width: 100%;
  }
  .dates-picker { 
    display: none;
  }

  .dates-picker-mobile {
    display: block;
    padding: 0 10px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin-top: 25px;
  }

  .dates-picker-mobile .dates-picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    gap: 20px;
  }

  .dates-picker-mobile .dates-picker-header h2 {
    font-size: 0.8rem;
    color: var(--vert);
    margin: 0;
    text-align: center;
    margin-left: 5px;
  }

  .dates-picker-mobile .toggle-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0;
    transition: transform 0.3s ease;
    color: var(--vert);
  }

  .rotate-down-dates {
    transform: rotate(90deg);
  }

  .rotate-up-dates {
    transform: rotate(-90deg);
  }

  .date-picker-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .date-picker-container-mobile .calendars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 10px;
  }

  .date-picker-container-mobile input[type="date"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    color: var(--vert);
  }

  .date-picker-container-mobile input[type="date"]:focus {
    border-color: var(--vert);
    outline: none;
  }

  .date-picker-container-mobile .validate-button {
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: var(--vert);
    color: var(--white);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .validate-button:hover {
    background-color: var(--jaune);
  }

  .slide-down-enter-active, .slide-down-leave-active {
    transition: max-height 0s ease-in-out, opacity 0s ease-in-out;
  }

  .slide-down-enter, .slide-down-leave-to /* .slide-down-leave-active in <2.1.8 */ {
    max-height: 0;
    opacity: 0;
  }

  .flight-card-wrapper {
    display: none;
  }

  .flight-card-wrapper-mobile {
    display: block;
  }

  .dates-and-choice {
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    padding: 0 10px;
  }

  .dates-picker {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }

  .date-picker-container {
    width: 100%;
    gap: 5px; /* Reduced padding */
    margin: auto;
  }

  .calendars {
    flex-direction: column;
  }

  .calendars .el-date-picker {
    font-size: 0.8rem; /* Reduced font size */
  }

  .train-or-flight {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 20px auto;
    border-radius: 50px; /* Rounded edges */
    overflow: hidden; /* Ensure children stay inside rounded container */
    padding: 0px; /* Reduced padding to decrease height */
  }

  .train-or-flight .choice-bloc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 5px 0; /* Reduced padding */
    cursor: pointer;
    font-weight: bold;
    color: #424242;
    border-right: 1px solid #ccc; /* Added border between choices */
  }

  .train-or-flight .choice-bloc img {
    width: 15px; /* Reduced size */
    height: 15px; /* Reduced size */
    margin-right: 5px;
  }

  .train-or-flight .choice-bloc p {
    font-size: 0.8rem; /* Reduced font size */
  }

  .train-or-flight .choice-bloc.left {
    background-color: #f9f9f9; /* Light background for left choice */
    border-top-left-radius: 50px; /* Rounded left edges */
    border-bottom-left-radius: 50px; /* Rounded left edges */
  }

  .train-or-flight .choice-bloc.right {
    background-color: #e0e4e5; /* Light background for right choice */
    border-top-right-radius: 50px; /* Rounded right edges */
    border-bottom-right-radius: 50px; /* Rounded right edges */
  }

  .train-or-flight .choice-bloc.highlighted {
    background-color: #d5dfe3; /* Highlighted background */
    border: 2px solid #424242; /* Darker border for highlighted choice */
  }

  .train-or-flight .choice-bloc:last-child {
    border-right: none; /* Remove border from last child */
  }

  .trip-first-infos {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .departure-arrival {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: flex-start; /* Align items to the left */
    text-align: left; /* Ensure text alignment is left */
  }

  .departure-arrival p {
    font-size: 0.9rem; /* Reduced font size */
    margin: 0 5px; /* Reduced margin to minimize space between text and image */
  }

  .departure-arrival img {
    width: 15px; /* Reduced size */
    height: 15px; /* Reduced size */
    margin: 0 5px; /* Reduced margin to minimize space between image and text */
  }

  .transportation-boolean {
    justify-content: center;
    margin: 5px 0; /* Reduced margin */
  }

  .transportation-boolean label {
    font-size: 0.8rem; /* Reduced font size */
  }

  .flight-card {
    display: none;
  }

  .flight-card-mobile {
    display: flex;
    flex-direction: column;
    max-width: 90%; /* Ensuring it does not exceed the width of the screen */
    margin: 10px auto; /* Center the card and reduce the margin */
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 21px;
  }

  .flight-card-left-mobile {
    width: 90%;
    border-radius: 0;
    margin: auto;
    padding: 0 20px;
  }

  .flight-card-right-mobile {
    border-radius: 0;
    padding: 0 10px;
    border-top: 1px solid var(--gray);
    border-radius: 0 0 21px 21px;
    justify-content: center;
    background-color: var(--vert);
    color: var(--jaune);
  }

  .flight-info {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }

  .mobile-way-column,
  .flight-info .flight-row {
    grid-column: span 3;
  }

  .mobile-way-column {
    text-align: center;
    font-weight: bold;
    color: var(--jaune);
    margin-top: 10px;
  }

  .flight-info .flight-row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  .flight-info .flight-row h3 {
    margin: 0;
  }

  .flight-info .line-container {
    grid-column: span 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
  }

  .flight-info .line-container h4 {
    margin: 0 5px;
  }

  .flight-info .time-row {
    grid-column: span 3;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 0.7rem;
  }

  .flight-info .time-row h3,
  .flight-info .time-row p {
    margin: 0;
  }

  .flight-info .time-row h3 {
    font-size: 0.5rem;
  }

  .row-separator {
    border-bottom: 1px dashed var(--gray);
    margin: 10px 0;
  }

  .rows-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .flight-row p {
    font-size: 0.7rem;
  }

  .time-row p {
    font-size: 0.7rem;
  }

  .way-text {
    margin: 0 auto;
    font-size: 0.8rem;
  }

  .row-separator {
    margin: 0px 0;
  }

  .from-to, .from-to-right {
    font-size: 0.8rem;
  }

  .text-container {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    justify-content: flex-end;
  }

  .text-container h2 {
    font-size: 1.1rem;
  }

  .flight-dates {
    margin-bottom: 0;
  }

  .flight-date-text {
    font-size: 0.8rem
  }

  .flight-popup-content {
    width: 80%; /* Increased width for mobile */
    margin-top: 150px;
  }

  .flight-popup-header .button-choose {
    padding: 5px 10px; /* Reduced padding for the button */
    font-size: 0.8rem;
  }

  .flight-popup-header h2 {
    font-size: 0.9rem;
  }

  .segment-first-column h3, .segment-third-column h3 {
    font-size: 0.8rem;
  }

  .segment-first-column h4, .segment-third-column h4 {
    font-size: 0.7rem;
  }

  .loading-container2 {
    height: 55vh;
    width: 90%;
  }

  /* --------  Partie TRAIN -------- */
  
  .train-mobile-layout {
    display: block;
  }
  .train-content-container {
    display: none;
    width: 95%;
  }

  .train-card-left {
    border-top-right-radius: 21px;
    padding: 20px;
  }

  .train-card-right {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 21px;
  }

  .train-card {
    display: flex;
    flex-direction: column;
    max-width: 100%; /* Ensuring it does not exceed the width of the screen */
    margin: 10px auto; /* Center the card and reduce the margin */
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 21px;
  }

  .train-card-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .train-card-right {
    border-radius: 0;
    padding: 10px;
    border-top: 1px solid var(--gray);
    border-radius: 0 0 21px 21px;
    justify-content: flex-end;
    background-color: var(--vert);
    color: var(--jaune);
  }

  .train-card-right .text-container {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;

    justify-content: flex-end;
  }

  .train-card-right .text-container h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  .train-card-right .text-container h4 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  /* Styles pour le bouton de bascule de la side column */
  .toggle-mobile-side-column-button {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 10px;
    height: 60px;
    background-color: var(--jaune); /* Gris avec opacité de 70% */
    color: var(--white);
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-mobile-side-column-button span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  .toggle-mobile-side-column-button span.rotate-up {
    transform: rotate(270deg); /* Rotation pour "fermé" */
  }

  .toggle-mobile-side-column-button span.rotate-down {
    transform: rotate(90deg); /* Rotation pour "ouvert" */
  }

  /* Styles pour le bouton de fermeture de la mobile-side-column */
  .close-mobile-side-column-button {
    width: 100%;
    padding: 10px;
    background-color: var(--vert);
    color: var(--white);
    text-align: center;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
  }

  /* Transition pour l'effet de glissement */
  .slide-up-enter-active, .slide-up-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-up-enter, .slide-up-leave-to /* .slide-up-leave-active in <2.1.8 */ {
    transform: translateY(100%);
  }

  .mobile-side-column {
    position: fixed;
    box-sizing: border-box;
    bottom: 60px; /* Ajusté pour être collé en bas au-dessus du bouton */
    width: 100%;
    background-color: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-top: 1px solid grey;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 30px; /* Remove padding */
    z-index: 1000;
    max-height: calc(70% - 50px); /* Ajuster pour prendre en compte la hauteur du bouton */
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
  }

  .mobile-side-column .selected-trip {
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
    width: auto;
  }

  .toggle-button {
    padding: 10px;
    margin: 0 auto;
  }

  .selected-trip .price h2 {
    margin: 0 auto;
  }

  .choose-button {
    margin-top: 10px;
    padding: 10px;
  }

  .escale {
    font-size: 0.7rem;
  }

  .escale img {
    width: 20px;
    height: 20px;
  }
  /* --------  FIN Partie TRAIN -------- */

  .loading-container2 {
    margin: 30px auto;
    border-radius: 30px;
    height: 70vh;
  }
}




</style>
