<template>
  <div class="back-arrow" @click="updateCurrentStep('selectWishes')">
    <img src="@/assets/img/voyage/previous.png" alt="Retour"> 
  </div>

  <div class="date-selection">
    <h2>Quand est-ce qu'on part {{ prenom }} ?</h2>
    <p>Connais-tu les dates exactes auxquelles tu veux partir ?</p>
    <div class="boolean-buttons">
      <button @click="setDateKnowledge(true)">Oui</button>
      <button @click="setDateKnowledge(false)">Non</button>
    </div>

    <div class="dates-picker" v-if="hasMadeChoice && knowsExactDates">
      <p>Sélectionne les dates :</p>
      <el-date-picker
        v-if="!isMobile"
        v-model="value1"
        type="daterange"
        range-separator="Au"
        start-placeholder="Départ"
        end-placeholder="Retour"
        :picker-options="pickerOptions"
        class="date-picker"
      />
      <div v-else class="native-date-picker">
        <label class="date-label">À partir de</label>
        <input type="date" v-model="value1[0]" class="native-date-input" />
        <label class="date-label">Jusqu'au</label>
        <input type="date" v-model="value1[1]" class="native-date-input" />
      </div>
      <button v-if="value1[0] && value1[1]" @click="submitDates" style="margin-top: 10px;">Valider</button>
    </div>

    <div class="dates-picker" v-if="hasMadeChoice && !knowsExactDates">
      <p>Donne-moi ta période et la durée pendant laquelle tu souhaites partir :</p>
      <el-date-picker
        v-if="!isMobile"
        v-model="value2"
        type="daterange"
        unlink-panels
        range-separator="-"
        start-placeholder="A partir du"
        end-placeholder="Jusqu'au"
        :picker-options="pickerOptions"
        class="date-picker"
      />
      <div v-else class="native-date-picker">
        <label class="date-label">À partir de</label>
        <input type="date" v-model="value2[0]" class="native-date-input" />
        <label class="date-label">Jusqu'au</label>
        <input type="date" v-model="value2[1]" class="native-date-input" />
      </div>
      <div class="dates-picker">
        <label for="durationSelect">Pendant quelle durée ?</label>
        <select id="durationSelect" v-model="selectedDuration" class="select-duration">
          <option disabled value="">Sélectionnez une durée</option>
          <option v-for="day in durations" :key="day" :value="day">{{ day }} jours</option>
        </select>
      </div>
      <button style="margin-top: 20px" v-if="selectedDuration" @click="submitFlexibleDates">Valider</button>
    </div>
  </div>
</template>


<script>
export default {
  props: ['prenom'],
  data() {
    return {
      knowsExactDates: null,
      value1: ['', ''], // Pour les dates exactes
      value2: ['', ''], // Pour les dates flexibles
      selectedDuration: null,
      durations: Array.from({ length: 30 }, (_, i) => i + 1), // Exemple de durées disponibles
      hasMadeChoice: false,
      isMobile: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      }
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setDateKnowledge(knowledge) {
      this.knowsExactDates = knowledge;
      this.hasMadeChoice = true;
    },
    updateCurrentStep(step) {
      this.$emit('updateStep', step);
    },
    parseDate(dateString) {
      const [year, month, day] = dateString.split('-');
      return new Date(year, month - 1, day);
    },
    submitDates() {
      const departureDate = this.isMobile ? this.parseDate(this.value1[0]) : new Date(this.value1[0]);
      const returnDate = this.isMobile ? this.parseDate(this.value1[1]) : new Date(this.value1[1]);

      if (departureDate < this.tomorrow()) {
        this.$message.error('Veuillez sélectionner une date à venir');
        return;
      }  

      if (returnDate <= departureDate) {
        this.$message.error('La date de retour doit être après la date de départ');
        return;
      }

      if (this.value1.length === 2) {
        this.$emit('dates-submitted', {
          departureDate,
          returnDate,
          exactDates: this.knowsExactDates,
        });
        this.$emit('updateStep', 'selectBudgetAndDeparture');
      }
    },
    tomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
    submitFlexibleDates() {
      const departureDate = this.isMobile ? this.parseDate(this.value2[0]) : new Date(this.value2[0]);
      const returnDate = this.isMobile ? this.parseDate(this.value2[1]) : new Date(this.value2[1]);

      if (!this.value2[0] || !this.value2[1]) {
        this.$message.error('Veuillez renseigner deux dates pour la période souhaitée.');
        return;
      }

      if (departureDate < this.tomorrow()) {
        this.$message.error('Veuillez sélectionner une date à venir.');
        return;
      }

      if (returnDate <= departureDate) {
        this.$message.error('La date de retour doit être après la date de départ.');
        return;
      }

      const diffInDays = (returnDate - departureDate) / (1000 * 60 * 60 * 24);
      if (diffInDays < this.selectedDuration) {
        this.$message.error('La durée sélectionnée dépasse le nombre de jours disponibles entre les dates.');
        return;
      }

      if (this.value2.length === 2 && this.selectedDuration) {
        this.$emit('dates-submitted', {
          departureDate,
          returnDate,
          exactDates: false,
          duration: this.selectedDuration,
        });
        this.$emit('updateStep', 'selectBudgetAndDeparture');
      }
    },

  }

};
</script>


<style>
.date-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.date-selection .boolean-buttons {
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: auto;
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.date-selection h2 {
  color: var(--vert);
  text-align: center;
  margin-top: -10px;
}

.date-selection p {
  text-align: center;
  margin-top: -5px;
}

.dates-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: auto; /* Centrer horizontalement le contenu */
  margin-top: 30px;
  text-align: center;
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.dates-picker p {
  text-align: center;
}

.dates-picker button {
  margin-bottom: 15px;
}

.date-label {
  font-size: 1rem;
  color: var(--vert);
  margin-bottom: 5px;
}

.native-date-input {
  width: 100%; 
  max-width: 300px; 
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc; 
  border-radius: 4px; 
  appearance: none; /* Supprime le style par défaut des navigateurs */
  background-color: white; 
  color: var(--vert); 
  cursor: pointer; 
  margin: 10px 0;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5H7z%22%20fill%3D%22%23434343%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Positionne l'icône à droite, centrée verticalement */
  background-size: 12px; 
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

/* Style au survol */
.native-date-input:hover {
  border-color: var(--marron); 
}

/* Style au focus */
.native-date-input:focus {
  border-color: var(--jaune);
  outline: none; /* Suppression de l'outline par défaut pour éviter les styles indésirables */
}

/* Style pour le date-picker pour s'assurer qu'il est sur sa propre ligne et centré */
.el-date-picker,
.native-date-picker input {
  width: 100%; /* Utilise la pleine largeur pour le date-picker */
  max-width: 500px; /* Limite la largeur maximale pour une meilleure apparence */
  margin: 0 auto 10px; /* Centrer horizontalement et espacement en bas */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.native-date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.native-date-picker input {
  margin-bottom: 10px;
}

.button-row, .select-row {
  width: 100%; /* Assure que les boutons et sélecteurs prennent la pleine largeur */
  display: flex;
  justify-content: center; /* Centre les boutons et sélecteurs horizontalement */
  margin-top: 10px; /* Espacement entre les éléments pour la clarté */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

/* Styles de base pour le sélecteur de durée */
.select-duration {
  width: 60%; 
  max-width: 100px; 
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc; 
  border-radius: 4px; 
  appearance: none; /* Supprime le style par défaut des navigateurs pour le select */
  background-color: white; 
  color: var(--vert); 
  cursor: pointer; 
  margin: 10px 0;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5H7z%22%20fill%3D%22%23434343%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Positionne l'icône à droite, centrée verticalement */
  background-size: 12px; 
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

/* Style au survol */
.select-duration:hover {
  border-color: var(--marron); 
}

/* Style au focus */
.select-duration:focus {
  border-color: var(--jaune);
  outline: none; /* Suppression de l'outline par défaut pour éviter les styles indésirables */
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
  .back-arrow {
    margin: 30px 0 20px 20px;
  }

  .date-selection h2 {
    font-size: 1.2rem !important;
    text-align: center;
    color: var(--vert);
    font-weight: bold;
    margin: 10px 0 30px 0;
  }

  .date-selection {
    min-height: 50vh !important;
    width: 90%; /* Assure que le conteneur prend toute la largeur */
    margin: auto;
    justify-content: flex-start;
  }

  .boolean-buttons {
    width: 80% !important;
    box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
    margin: 0 auto !important;
  }

  .el-date-picker,
  .native-date-picker input {
    max-width: none; /* Supprime la largeur maximale */
    width: 100%; /* Utilise toute la largeur disponible */
    margin: 0 auto 10px; /* Centrer horizontalement et espacement en bas */
    box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
  }

  .select-duration {
    width: 80%; /* Prend 80% de la largeur disponible */
    max-width: none; /* Supprime la largeur maximale */
    box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
  }
}
</style>

