<template>
  <div class="why-alego-section-container">
    <button class="feedback-button" @click="openFeedbackOverlay">Faire un retour</button>
    <div class="why-alego-section">
      <h2 class="section-title">Pourquoi Alego ?</h2>
      <div class="cards-container">
        <div class="card">
          <img src="@/assets/img/AboutUs/personnalisation.png" alt="Personnalisation" class="card-image" />
          <div class="card-content">
            <h3 class="card-title">PERSONNALISATION</h3>
            <p class="card-text">
              Avec Alego, finis les voyages standardisés ! Grâce à l’intelligence artificielle, notre plateforme analyse
              tes centres d’intérêts et tes préférences pour te proposer des destinations, activités, et hébergements
              parfaitement adaptés à ton style. Que tu sois amateur de culture, fan de nature, ou en quête de farniente,
              Alego te guide vers les choix qui te ressemblent. Chaque voyage devient unique, comme toi.
            </p>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/img/AboutUs/rapidite.png" alt="Rapidité" class="card-image" />
          <div class="card-content">
            <h3 class="card-title">RAPIDITÉ</h3>
            <p class="card-text">
              Organiser un voyage peut prendre des heures, voire des jours. Alego te fait gagner un temps précieux en te
              proposant instantanément des recommandations basées sur tes envies et ton budget. En quelques clics, tu
              sélectionnes ta destination, ton transport, ton hébergement, et tu crées ton planning d’activités. Plus
              besoin de passer des heures à comparer et chercher : tout est réuni pour que tu puisses partir l’esprit
              léger et rapidement.
            </p>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/img/AboutUs/simplification.png" alt="Simplification" class="card-image" />
          <div class="card-content">
            <h3 class="card-title">SIMPLIFICATION</h3>
            <p class="card-text">
              Alego réunit tous les éléments de ton voyage en un seul endroit. Plus besoin de jongler entre des dizaines
              de sites pour réserver ton vol, trouver un hôtel et planifier tes activités. Notre plateforme te permet de
              gérer l’ensemble de ton séjour en toute simplicité. Un seul outil, une seule interface, et tout ce dont tu as
              besoin pour voyager sereinement. Alego simplifie la préparation pour que tu te concentres sur l’essentiel :
              profiter de ton voyage.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Overlay component -->
    <OverlayFeedback :isVisible="showOverlay" @close="closeFeedbackOverlay" />
  </div>
</template>

<script>
import OverlayFeedback from "@/components/ContactOverlay.vue";

export default {
  name: 'AboutUsFirst',
  components: {
    OverlayFeedback,
  },
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    openFeedbackOverlay() {
      this.showOverlay = true;
    },
    closeFeedbackOverlay() {
      this.showOverlay = false;
    }
  }
};
</script>

<style scoped>
.why-alego-section-container {
  position: relative;
}

.feedback-button {
  position: absolute;
  top: 30px; /* Ajustez en fonction de l'espacement souhaité */
  left: 6%;
  background-color: var(--jaune);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 20px 30px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.feedback-button:hover {
  background-color: var(--vert);
}

.why-alego-section {
  text-align: center;
  width: 90%;
  margin: auto;
  padding-top: 60px; /* Espace pour le bouton */
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 50px;
  color: var(--dark-gray);
}

.section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background-color: var(--dark-gray);
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 20px;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  width: 30%;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 1rem;
  text-align: justify;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--jaune);
  margin-bottom: 1rem;
  margin-top: 0px;
}

.card-text {
  font-size: 0.8rem;
  color: var(--dark-gray);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%; /* Prend toute la largeur disponible */
    margin-bottom: 20px; /* Ajoute un espace entre les cartes */
  }
  .feedback-button {
    margin-top: 0;
    padding: 10px;
  }
}
</style>
